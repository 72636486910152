import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRocket,
  faGlobe,
  faArrowUp,
  faLaptopCode,
  faHiking,
  faFlask,
  faHandshake,
  faUserAstronaut,
  faSeedling,
} from "@fortawesome/free-solid-svg-icons";
import "../../style/fellowshipcard.scss";

const FellowShipBenefits = () => {
  const horizontalData = [
    {
      icon: faRocket,
      text: "Founded and Instituted by ImmverseAI in 2023.",
    },
    {
      icon: faArrowUp,
      text: "Revolutionizing AI through Innovation. ",
    },
    {
      icon: faGlobe,
      text: "Be the change maker",
    },
  ];

  const verticalData = [
    {
      icon: faLaptopCode,
      text: "Master LLMs, Voice Cloning, GANs, Generative AI.",
    },
    {
      icon: faHiking,
      text: "Pushing boundaries, breaking conventions, daring dreams.",
    },
    {
      icon: faUserAstronaut,
      text: "Transformative adventure awaits at ImmverseAI Fellowship.",
    },
  ];

  const rowData = [
    {
      icon: faFlask,
      text: "Grab your seat on the frontrow of AI.",
    },
    {
      icon: faHandshake,
      text: "Apply now, It offers a year-long odyssey for nurturing bright minds in AI.",
    },
    {
      icon: faSeedling,
      text: "Become a Fellow: pioneer, trailblazer, visionary architect. ",
    },
  ];

  return (
    <>
      <div className="text-[#c3c1c7] text-4xl sm:text-3xl md:text-4xl xl:text-6xl flex justify-center items-center font-bold">
        <h1>
          <span className="text-pink-700">Fellowship</span> Benefits
        </h1>
      </div>
      <div className="fellowship-card-main-container flex">
        <div className="fellowship-card-container">
          <div className="fellowship-horizontal-cards">
            {horizontalData.map((data, index) => (
              <div key={index} className="card fellowship-horizontal-card">
                <div
                  className="icon-content"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={data.icon}
                    size="2x"
                    className="fa-icon"
                  />
                  <h3
                    style={{
                      color: "#d71d89",
                      fontWeight: "700",
                      fontSize: "25px",
                    }}
                  >
                    {data.heading}
                  </h3>
                </div>
                <p>{data.text}</p>
              </div>
            ))}
          </div>

          <div className="fellowship-vertical-cards">
            {verticalData.map((data, index) => (
              <div key={index} className="card fellowship-vertical-card">
                <div
                  className="icon-content"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={data.icon}
                    size="2x"
                    className="fa-icon"
                  />
                  <h3
                    style={{
                      color: "#d71d89",
                      fontWeight: "700",
                      fontSize: "25px",
                    }}
                  >
                    {data.heading}
                  </h3>
                </div>

                <p>{data.text}</p>
              </div>
            ))}
          </div>

          <div className="fellowship-horizontal-cards-3">
            {rowData.map((data, index) => (
              <div key={index} className="card fellowship-horizontal-card">
                <div
                  className="icon-content"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={data.icon}
                    size="2x"
                    className="fa-icon"
                  />
                  <h3
                    style={{
                      color: "#d71d89",
                      fontWeight: "700",
                      fontSize: "25px",
                    }}
                  >
                    {data.heading}
                  </h3>
                </div>

                <div>
                  <p>{data.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default FellowShipBenefits;
