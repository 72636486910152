import React from 'react'
import { useEffect } from 'react';
import Layout from './../components/Layout';
import TermsOfService from '../components/TermsOfService';

const TermsOfServicePage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Layout>
            <TermsOfService />
        </Layout>
    )
}

export default TermsOfServicePage