import React from "react";
import ellipse3 from "../assets/About/ellipse3.svg";
import ellipse4 from "../assets/About/ellipse4.svg";
import "../style/team.scss";
import Contactform from "./Contactform";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { domainData } from "./Domain";
import { useState, useEffect } from "react";
import "react-image-lightbox/style.css";
import { useCallback } from "react";
import CustomImageViewer from "./CustomImageViewer";

import Gallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import MyGalleryComponent from "./team-component";
import GalleryLoadingComponent from "./GalleryLoadingcomponent";

const TeamGallery = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [imgUrls, setImgUrls] = useState([]);
  const [imgCaption, setImgCaption] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(true);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      // try {
      //   const response = await axios.get(`${domainData.url}/get-all-images/`, {
      //     headers: {
      //       "X-Api-Key": "Immverse-API-sam-0987654321",
      //     },
      //   });
      //   const imagesData = response.data.images.data;
      //   const imageUrls = imagesData.map(
      //     (imageData) => imageData.image.original_url
      //   );

      //   console.log("iaa",imageUrls)
      //   const imageCaption = imagesData.map((imageData) => imageData.caption);

      //   setImgCaption(imageCaption);
      //    setLoader(false)

      //   setImgUrls(imageUrls);
      // } catch (error) {
      //   console.log("Error fetching data:", error);
      // } finally {
      //   setLoading(false);
      // }

      try {
        let allImageUrls = [];
        let allImageCaptions = [];
        let currentPage = 1;
        const imagesPerPage = 10;
        let totalImagesToFetch = 12;

        while (totalImagesToFetch > 0) {
          const response = await axios.get(
            `${domainData.url}/get-all-images/?page=${currentPage}&limit=${imagesPerPage}`,
            {
              headers: {
                "X-Api-Key": "Immverse-API-sam-0987654321",
              },
            }
          );

          const imagesData = response.data.images.data;

          if (imagesData.length === 0) {
            // No more images to fetch
            break;
          }

          const imageUrls = imagesData.map(
            (imageData) => imageData.image.original_url
          );

          const imageCaption = imagesData.map((imageData) => imageData.caption);

          allImageCaptions = [...allImageCaptions, ...imageCaption];
          setLoader(false);

          allImageUrls = [...allImageUrls, ...imageUrls];
          setImgUrls(allImageUrls);

          totalImagesToFetch -= imagesData.length;
          currentPage++;
        }

        // Set the accumulated captions after the loop
        setImgCaption(allImageCaptions);
      } catch (error) {
        console.log("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const openImageViewerByCaption = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Discover the talented individuals behind Immverse AI. Meet our team of innovators driving advancements in avatar technology and digital experiences.
          "
        />
        <meta
          name="keywords"
          content="Immverse, Immverse AI, ImmverseAI,Artificial Intelligence, AI, Personalized AI, Immortalizing
        Humans, Human Preservation, Digital Avatar, Virtual Presence"
        />
        <meta name="author" content="Immverse AI" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.immverseai.ai/" />
        <meta
          property="og:title"
          content="Immverse AI | Immortalizing Humanity through AI"
        />
        <meta property="og:image" content="/immverseLogo.svg" />
        <meta property="twitter:card" content="/immverseLogo.svg" />
        <meta property="twitter:url" content="https://www.immverseai.ai/" />
        <meta
          property="twitter:title"
          content="ImmverseAI - Welcome To ImmverseAI"
        />
        <meta
          property="twitter:description"
          content="Discover the talented individuals behind Immverse AI. Meet our team of innovators driving advancements in avatar technology and digital experiences.
          "
        />
        <title>ImmverseAI - The 'A' Team</title>
      </Helmet>
      <div
        className="team-container"
        style={{
          backgroundImage: `url(${ellipse3})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top right",
        }}
      >
        <div className="team-content">
          <div className="ellipse4">
            <img src={ellipse4} alt="" width="600" />
          </div>
          <h1>
            <span>The AI </span> Team
          </h1>
          <p>
            ImmverseAI is driven by seasoned technology leaders, serial
            entrepreneurs, startup founders, and a diverse team of 50+ tech
            professionals from the heart of India and Silicon Valley, USA,
            driving cutting-edge innovation and shaping the future of humanity.
          </p>

          <div className="gallery">
            {loader ? (
              <GalleryLoadingComponent />
            ) : (
              imgUrls.map((src, index) => (
                <div key={index} className="image-container">
                  <img
                    className="inner-image"
                    src={src}
                    onClick={() => openImageViewer(index)}
                    alt=""
                    loading="lazy"
                  />
                  <p
                    onClick={() => openImageViewerByCaption(index)}
                    style={{ cursor: "pointer" }}
                  >
                    {imgCaption[index]
                      ? imgCaption[index].length > 50
                        ? `${imgCaption[index].slice(0, 50)}...`
                        : imgCaption[index]
                      : ""}
                  </p>
                </div>
              ))
            )}
          </div>

          {isViewerOpen && (
            <MyGalleryComponent
              imgUrls={imgUrls}
              imgCaption={imgCaption}
              currentImage={currentImage}
              closeImageViewer={closeImageViewer}
            />
          )}

          <Contactform />
        </div>
        <hr />
      </div>
    </>
  );
};

export default TeamGallery;
