import React, { useEffect, useState } from "react";
import ellipse3 from "../assets/About/ellipse3.svg";
import ellipse4 from "../assets/About/ellipse4.svg";
import "../style/blog.scss";
import generative from "../assets/Blog/generativeAI.svg";
import immverse from "../assets/Blog/ImmverseAI.svg";
import Revolutiona from "../assets/Blog/Revolutiona.svg";
import immverseellipse from "../assets/Blog/immverse-ellipse.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import Contactform from "./Contactform";
import { domainData } from "./Domain";
import { Helmet } from "react-helmet-async";
import Select from "react-select";
// import ellipse3 from "../assets/new-ellipse.png"
// import {Shimmer} from 'react-shimmer-effects';
// import { ShimmerThumbnail } from 'react-shimmer-effects';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import BlogLoadingComponent from "./BlogLoadingComponent";

const Blog = () => {
  const [selectedOption, setSelectedOption] = useState("Select an Option");
  const toggleOptions = () => {
    setIsOpen(!isOpen);
  };
  const selectOption = (value) => {
    setSelectedOption(value);
    toggleOptions();
  };

  const [message, setMessage] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleSelect = (category) => {
    setIsOpen(false);
    setFormData({
      ...formdata,
      blog: category.id,
    });
  };

  const [blogData, setBlogdata] = useState([]);
  const [categories, setCategories] = useState([]);
  const [formdata, setFormData] = useState({
    keyword: "",
    blog: "",
  });
  const [loader, setLoader] = useState(true);

  const [shimmeringImages, setShimmeringImages] = useState([]);

  // Initialize shimmering state for each image on component mount
  useEffect(() => {
    setShimmeringImages(blogData.map((user) => user.id));
  }, [blogData]);

  // Function to handle image load completion
  const handleImageLoad = (id) => {
    // Remove the image from shimmering state once loaded
    setShimmeringImages((prevShimmering) =>
      prevShimmering.filter((imageId) => imageId !== id)
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formdata,
      [name]: value,
    });
  };

  const options = categories.map((category) => ({
    value: category.id,
    label: category.category,
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "black",
      color: "white",
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: "black",
      color: "white",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
  };

  useEffect(() => {
    const getData = async () => {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${domainData.url}/get-all-blogs`,
        headers: {
          "x-api-key": "Immverse-API-sam-0987654321",
        },
      };

      await axios
        .request(config)
        .then((response) => {
          setBlogdata(response.data.blogs.data);
          setLoader(false);
          setCategories(response.data.categories);
        })
        .catch((error) => {});
    };
    getData();
  }, []);

  const SearchData = async (e) => {
    e.preventDefault();
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${domainData.url}/get-all-blogs?search=${formdata.keyword}&id=${formdata.blog}`,
      headers: {
        "x-api-key": "Immverse-API-sam-0987654321",
      },
    };

    try {
      const response = await axios.request(config);

      if (response.data.blogs.data.length === 0) {
        setBlogdata([]);
        setMessage("No data found");
      } else {
        setBlogdata(response.data.blogs.data);

        setMessage("");
      }
    } catch (error) {}
  };

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Stay updated with the Immverse AI blog. Explore our latest insights, news, and articles on avatar technology and digital experiences.
          "
        />
        <meta
          name="keywords"
          content="Immverse, Immverse AI, ImmverseAI,Artificial Intelligence, AI, Personalized AI, Immortalizing
        Humans, Human Preservation, Digital Avatar, Virtual Presence"
        />
        <meta name="author" content="Immverse AI" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.immverseai.ai/" />
        <meta
          property="og:title"
          content="Immverse AI | Immortalizing Humanity through AI"
        />
        <meta property="og:image" content="/immverseLogo.svg" />
        <meta property="twitter:card" content="/immverseLogo.svg" />
        <meta property="twitter:url" content="https://www.immverseai.ai/" />
        <meta
          property="twitter:title"
          content="ImmverseAI - Welcome To ImmverseAI"
        />
        <meta
          property="twitter:description"
          content="Stay updated with the Immverse AI blog. Explore our latest insights, news, and articles on avatar technology and digital experiences.
          "
        />
        <title>ImmverseAI - Blogs</title>
      </Helmet>
      <div
        className="blog-container"
        style={{
          backgroundImage: `url(${ellipse3})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top right",
        }}
      >
        <div className="blog-content">
          {/* <div className="ellipse3">
            <img src={ellipse3} alt=""  />
          </div> */}
          <div className="ellipse4">
            <img src={ellipse4} alt="" width="600" loading="lazy" />
          </div>
          <h1>
            <span>Insights </span>& Perspectives
          </h1>
          <p>
            Dive into a world of knowledge and innovation with our
            thought-provoking insights and perspectives. Explore diverse topics,
            gain valuable knowledge, and stay ahead in the ever-evolving
            landscape of ideas.
          </p>
          <form onSubmit={SearchData}>
            <div className="search-blog">
              <input
                className="input1"
                type="text"
                id="keyword"
                name="keyword"
                value={formdata.keyword}
                onChange={handleInputChange}
                placeholder="Enter your keyword"
              />

              <div className="custom-dropdown">
                <Select
                  value={categories.find(
                    (category) => category.value === selectedCategory
                  )}
                  onChange={handleSelect}
                  options={categories.map((category) => ({
                    value: category.id,
                    label: category.category,
                  }))}
                  placeholder="Select Blog Category"
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      height: "60px",
                      borderRadius: "40px",
                      border: "2px solid #333135",
                      width: "13.5rem",
                      backgroundColor: "rgba(22, 22, 22, 0.2)",
                      outline: "none",
                      boxShadow: state.isFocused ? 0 : 0,
                      color: " #d4d1db",
                      ":hover": {
                        border: "2px solid #333135",
                      },
                      ":focus": {
                        border: "none",
                        outline: "none",
                        boxShadow: "0 0 0 2px #333135",
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      color: state.isSelected ? " #d4d1db" : " #d4d1db",
                      backgroundColor: state.isSelected
                        ? "#333135"
                        : "transparent",
                      ":hover": {
                        backgroundColor: "#333135",
                        color: " #d4d1db",
                      },
                    }),
                    menu: (provided) => ({
                      ...provided,
                      backgroundColor: "rgba(22, 22, 22, 0.5)",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: " #d4d1db",
                    }),
                    indicatorSeparator: (provided) => ({
                      ...provided,
                      backgroundColor: "transparent",
                    }),
                    indicatorContainer: (provided) => ({
                      ...provided,

                      display: "none",
                    }),
                  }}
                />
              </div>

              <div className="btn-position">
                <button>Search</button>
              </div>
            </div>
          </form>

          {message && <p className="error-message">{message}</p>}

          {loader ? (
            <BlogLoadingComponent />
          ) : (
            <div className="blogs">
              {Array.isArray(blogData) &&
                blogData.length > 0 &&
                blogData.map((user) => (
                  <Link
                    key={user.id}
                    className="blog-card"
                    to={`/blogdetails/${user.id}`}
                  >
                    {user.main_image && user.main_image.original_url && (
                      <img
                        src={user.main_image.original_url}
                        alt=""
                        style={{ height: "280px", width: "100%" }}
                        loading="lazy"
                      />
                    )}

                    <div className="card-content">
                      <p className="generativeAI">Generative AI</p>
                      <h1>{user.blog_title.slice(0, 15)}...</h1>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: user.blog_content.slice(0, 150),
                        }}
                      ></p>
                    </div>
                  </Link>
                ))}
            </div>
          )}
          <Contactform />
        </div>
        <hr />
      </div>
    </>
  );
};

export default Blog;
