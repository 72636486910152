import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import "../style/privacypolicy.scss";

const PrivacyPolicyDetails = () => {

    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Immverse AI is on a mission to immortalize humans by harnessing the power of advanced
        artificial intelligence. We create personalized AI representations, preserving and embodying individual human
        personalities for eternity."
                />
                <meta
                    name="keywords"
                    content="Immverse, Immverse AI, ImmverseAI,Artificial Intelligence, AI, Personalized AI, Immortalizing
        Humans, Human Preservation, Digital Avatar, Virtual Presence"
                />
                <meta name="author" content="Immverse AI" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.immverseai.ai/" />
                <meta
                    property="og:title"
                    content="Immverse AI | Immortalizing Humanity through AI"
                />
                <meta property="og:image" content="/immverseLogo.svg" />
                <meta property="twitter:card" content="/immverseLogo.svg" />
                <meta property="twitter:url" content="https://www.immverseai.ai/" />
                <meta
                    property="twitter:title"
                    content="ImmverseAI - Welcome To ImmverseAI"
                />
                <meta
                    property="twitter:description"
                    content="Immverse AI is on a mission to immortalize humans by harnessing the power of advanced
        artificial intelligence. We create personalized AI representations, preserving and embodying individual human
        personalities for eternity."
                />
                <title>ImmverseAI -Welcome to ImmverseAI</title>
            </Helmet>
            <div>
                <p><strong>Last updated: August 24, 2023</strong></p>

                <p>Privacy Policy outlines how our ad network collects, uses, and shares information from users of our platform. We understand the importance of privacy and are committed to protecting the personal information of our users. Please read this privacy notice carefully as it will help you understand what we do with the information that we collect.</p>

                <p><strong>IMMVERSE INNOVATIONS PRIVATE LIMITED</strong>, if you reside in India or outside India (referred to as "Company" or "we" or "us" or "our") is the owner of the website domain at <a href="https://www.immverse.ai">https://www.immverse.ai</a> and other associated/ancillary applications, products, websites, and services and all other variations and sub-domains of the same (referred to as "Platform"); and any service availed by Users (hereinafter referred to as "you", "your" or "User") through the Platform is conditioned upon your acceptance of the Privacy Policy contained in Terms of Service as available on the Platform and this privacy policy ("Privacy Policy").</p>

                <p><strong>THIS PRIVACY POLICY IS AN ELECTRONIC RECORD IN THE FORM OF AN ELECTRONIC CONTRACT FORMED UNDER ANY RELEVANT STATUTE OR REGULATION UNDER ANY APPLICABLE JURISDICTION, INCLUDING WITHOUT LIMITATION THE INFORMATION TECHNOLOGY ACT, 2000 AND THE RULES MADE THEREUNDER AND THE AMENDED PROVISIONS PERTAINING TO ELECTRONIC DOCUMENTS / RECORDS IN APPLICABLE STATUTES ACROSS JURISDICTIONS. THIS PRIVACY POLICY DOES NOT REQUIRE ANY PHYSICAL, ELECTRONIC OR DIGITAL SIGNATURE.</strong></p>

                <p>We are committed to keeping your personal data private and secure. We process any personal data we collect from you in accordance with the applicable laws and regulations and the provisions of this Privacy Policy. The purpose of this Privacy Policy is to ensure that there is an intact charter to collect, use and protect any personal and/or sensitive data collected by us. This Policy defines our procedure for collection, usage, processing, disclosure and protection of any information obtained by us through the Platform. Capitalized terms that are not defined in this Privacy Policy shall have the same meaning as ascribed to them in our Terms of Service. Any reference made to Privacy Policy in this document shall mean and refer to the latest version of the Privacy Policy.</p>

                <p>This privacy notice describes how and why we might collect, store, use, and/or share your information when you use our services, such as when you:</p>

                <p>
                    <p>Visit our website at <a href="https://www.immverse.ai">https://www.immverse.ai</a>, or any website of ours that links to this privacy notice;</p>
                    <p>Download and use our mobile application (ImmverseAI), or any other application of ours that links to this privacy notice;</p>
                    <p>Engage with us in other related ways, including any sales, marketing, or events.</p>
                </p>
                <p><strong>1. CONSENT.</strong></p>

                <p><strong>THIS PRIVACY POLICY IS A LEGALLY BINDING DOCUMENT BETWEEN YOU AND THE COMPANY. THE TERMS OF THIS PRIVACY POLICY WILL BE EFFECTIVE UPON YOUR ACCEPTANCE OF THE TERMS OF SERVICE OR BY YOUR USE OF OUR SERVICES AND WILL GOVERN THE RELATIONSHIP BETWEEN COMPANY AND YOU WITH RESPECT TO THE COLLECTION AND TREATMENT OF INFORMATION SHARED BY YOU. PLEASE READ THIS PRIVACY POLICY CAREFULLY AS IT AFFECTS YOUR RIGHTS AND LIABILITIES UNDER LAW. BY USING THIS PLATFORM AND AVAILING OUR SERVICES, YOU CONFIRM THAT YOU UNDERSTAND, AGREE AND CONSENT TO THIS PRIVACY POLICY. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT USE THIS WEBSITE OR AVAIL OUR SERVICES.</strong></p>

                <p>By using our website / application, you hereby consent to our Privacy Policy and agree to its terms & conditions.</p>

                <p>Please be advised that any Information procured by us shall be:</p>

                <p>
                    <p>processed fairly and lawfully for rendering the Services;</p>
                    <p>obtained only for specified and lawful purposes;</p>
                    <p>adequate, relevant and not excessive in relation to the purpose for which it is required;</p>
                    <p>able to be reviewed by the User, from time to time and updated-if need arises; and</p>
                    <p>not kept longer than for the time which it is required or the purpose for which it is required or as required by the applicable law.</p>
                </p>

                <p>It shall be used only for providing and improving user-facing features that are prominent in our Platform’s interface. If you do not agree with this Privacy Policy, you may refuse or withdraw your consent at any time, or alternatively choose to not provide us with any Personal Information. You understand that under such circumstances, we may be unable to render Services. Any such intimation to withdraw your consent can be sent to <a href="mailto:team@immverse.ai">team@immverse.ai</a>.</p>

                <p><strong>WE SHALL NOT BE LIABLE FOR ANY LOSS OR DAMAGE SUSTAINED BY REASON OF ANY DISCLOSURE (INADVERTENT OR OTHERWISE) OF ANY DATA, IF THE SAME IS EITHER (A) REQUIRED FOR SHARING YOUR INFORMATION FOR LEGITIMATE PURPOSES; OR (B) WAS CAUSED THROUGH NO FAULT, ACT, OR OMISSION OF THE COMPANY.</strong></p>

                <p><strong>2. INFORMATION COLLECTED BY US.</strong></p>

                <p><strong>2.1. “Personal Data”</strong> means and includes any Information that relates to a natural person through which an individual is identified, such as the name, email id, country of origin, or any other information relevant to product choice and preferences provided by a User, including but not limited to information gathered through availing Services.</p>

                <p>i) We collect Personal Data that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>

                <p>ii) “Payment Data” We may collect data necessary to process your payment if you make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is processed by a third-party service (Payment Gateway).</p>

                <p>iii) We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter, or other social media account. If you choose to register in this way, we will collect the information described in the section called ‘HOW DO WE HANDLE YOUR SOCIAL LOGINS?’ in this Privacy Policy.</p>

                <p>iv) If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:</p>

                <p><strong>Mobile Device Access:</strong> We may request access or permission to certain features from your mobile device, including your mobile device's camera, microphone, SMS message (For OTP), social media accounts, storage, and other features. If you wish to change our access or permissions, you may do so in your device's settings.</p>

                <p><strong>Mobile Device Data:</strong> We automatically collect device information (such as your mobile device ID, model, and manufacturer), operating system, version information and system configuration information, device and application identification numbers, browser type and version, Internet Protocol (IP) address (or proxy server). If you are using our application(s), we may also collect information about your mobile device’s operating system or platform, the type of mobile device you use, your mobile device’s unique device ID.</p>

                <p><strong>Push Notifications:</strong> We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device's settings.</p>

                <p>This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting Purposes. All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>

                <p><strong>2.2. “Technical Information”</strong> means and includes any Information gathered through various technologies that may employ cookies or similar technologies to automatically record certain information from your device through which you use the Platform. This technical information may include your Internet Protocol (IP) address.</p>

                <p>i) We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes. The information we collect includes:</p>

                <p><strong>Log and Usage Data:</strong> Log and usage data are service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps', and hardware settings.</p>

                <p><strong>Device Data:</strong> We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</p>

                <p><strong>2.3. &Non-Personal Information”</strong> means and includes any information that does not reveal your specific identity, such as browser information, information collected through Cookies (as defined below), pixel tags and other technologies, demographic information, etc. As is true with most websites, our Company gathers some information automatically when you visit the Platform. When you use the Platform, we may collect certain information about your computer or mobile to facilitate, evaluate and verify your use of the Platform. For example, we may store environmental variables, such as browser type, operating system, speed of the central processing unit (CPU), referring or exit web pages, click patterns. This information is generally collected in aggregate form, without identifying any User individually.</p>

                <p>(The Personal Data, Technical Information, Non-Personal Information, Etc. are collectively referred to as “Information”)</p>

                <p><strong>2.4. Information may be collected in various ways including during the course of you registering as a User on the Platform or availing certain Services offered on the Platform.</strong></p>

                <p><strong>2.5. We may receive Information about you from third-party platforms, such as social media platforms, marketing and advertising firms, commercially available sources and business partners to whom you have consented disclosure of such Information.</strong></p>

                <p><strong>2.6. Please do note that each category of Information may be treated differently as per this Privacy Policy.</strong></p>

                <p><strong>2.7. We do not collect any Sensitive Information.</strong></p>
                <p>3. PURPOSE OF COLLECTING THE INFORMATION.</p>
                <p>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</p>
                <p>
                    <p>To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log into your account, as well as keep your account in working order.</p>
                    <p>To deliver and facilitate delivery of services to the user. We may process your information to provide you with the requested service.</p>
                    <p>To send administrative information to you. We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.</p>
                    <p>To enable user-to-user communications. We may process your information if you choose to use any of our offerings that allow for communication with another user.</p>
                    <p>To request feedback. We may process your information when necessary to request feedback and to contact you about your use of our Services.</p>
                    <p>To send you marketing and promotional communications. We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time.</p>
                    <p>To deliver targeted advertising to you. We may process your information to develop and display personalized content and advertising tailored to your interests more.</p>
                    <p>To protect our Services. We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and Prevention.</p>
                    <p>To identify usage trends. We may process information about how you use our Services to better understand how they are being used so we can improve Them.</p>
                    <p>To determine the effectiveness of our marketing and promotional campaigns. We may process your information to better understand how to provide marketing and promotional campaigns that are most relevant to you.</p>
                    <p>To save or protect an individual&s vital interest. We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.</p>
                </p>

                <p>4. STORAGE, SHARING AND DISCLOSURE OF YOUR INFORMATION.</p>
                <p>4.1. Your Information will primarily be stored in electronic form; provided, however, that certain data can also be stored in physical form. We primarily store all Information within the territory of India.</p>
                <p>4.2. We do not rent, sell or disclose or share any Information that we collect from you, with third parties, save and except in order to provide you with the Services. Any such disclosure, if made, shall be in accordance to this Privacy Policy and as per the procedure prescribed by law and in compliance with our legal obligations. We may share your Information in circumstances and for the purposes as specified hereunder:</p>
                <p>4.3. We shall share the information to the third-party service providers/ vendors, to provide you with the Services.</p>
                <p>4.4. We may share your data with third-party vendors, service providers, contractors, or agents (‘Third parties’) who perform services for us or on our behalf and require access to such information to do that work. We have contracts in place with our third parties, which are designed to help safeguard your personal information. This means that they cannot do anything with your personal information unless we have instructed them to do it. They will also not share your personal information with any organization apart from us. They also commit to protect the data they hold on our behalf and to retain it for the period we instruct.</p>
                <p>4.5. We may disclose any Information provided by you on the Platform as may be deemed necessary or appropriate:</p>
                <p>
                    <p>under applicable law, including laws outside your country of residence;</p>
                    <p>to comply with legal process;</p>
                    <p>to respond to requests from public and government authorities including those outside your country of residence;</p>
                    <p>to protect our operations or those of any of our affiliates;</p>
                    <p>to protect our rights, privacy, safety or property, and/that of our affiliates, you or others;</p>
                    <p>to allow us to pursue available remedies or limit the damages that we may sustain; or</p>
                    <p>to protect against legal liability;</p>
                    <p>to protect the personal safety of Users of the Platform;</p>
                    <p>to prevent or investigate possible wrongdoing in connection with the Platform.</p>
                    <p>Vital Interests. We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</p>
                </p>

                <p>4.6. Merger or Acquisition: We may share Information upon merger or acquisition of Company with another company. We shall transmit and transfer the Information upon the acquisition or merger of Company with another company.</p>
                <p>4.7. With our service providers: We may share Information with other service providers on a need-to-know basis, subject to obligations of confidentiality for the provision of Services. We hereby clarify that we work with institutions, vendors, partners, advertisers, and other service providers, including (but not limited) to those who provide products or services such as contact Information verification, website hosting, data analysis, providing infrastructure, information technology services, auditing services, and other similar services, in different industries and categories of business by virtue of lawful contracts instituted between such third parties and Company to improve our product and services. Accordingly, we may share your Information with such service providers to provide you with Services.</p>
                <p>4.8. Employees/Agents of Company: We follow a strict confidentiality policy with regard to the disclosure of confidential information to our employees or other personnel. There may be situations where we may disclose the confidential information only to those of our employees and other personnel on a need-to-know basis. Any breach of confidential information by the employees, personnel within the Company is dealt with stringently by us.</p>

                <p>5. LINK TO OTHER WEBSITES AND THIRD-PARTY SERVICES.</p>
                <p>5.1. Our Platform may provide links to other sites. These links are provided for your convenience only and the provision of these links does not mean that sites are related or associated with us. Please note that these sites have their own terms of use and privacy policy. You should check their privacy policy before you submit Your Personal Information or any other data with them. We don’t guarantee the content and the security of those sites.</p>
                <p>5.2. We may have certain features on our Platform which may be hosted by third parties; your interaction with such features shall be governed by the privacy policy of such third parties.</p>
                <p>5.3. Our platform’s use and transfer of information received from Google APIs to any other app will adhere to Google API Services User Data Policy available at https://developers.google.com/terms/api-services-user-data-policy, including the Limited Use requirements.</p>
                <p>5.4. We shall not be responsible for any loss, damage, claim, or expense caused as a result of you accessing these third-party sites and features.</p>
                <p>5.5. We may use your Information to send you promotional Information about third parties which, we think you may find interesting, if you tell us that you wish this to happen. We shall not be responsible for any disclosure of Information due to unauthorized third-party access or other acts of third parties or acts or omissions beyond our reasonable control and you agree that you will not hold us responsible for any breach of security unless such breach has been caused as a direct result of our negligence or willful default.</p>
                <p>5.6. We use support services of third-party platforms and/or companies to direct you to payment gateways when you opt to pay for our Services. Your financial information is collected, stored, and retained by such third-party platforms. We and such designated third-party platforms undertake measures designed to provide a security level that is appropriate to the risks of processing your personal information. However, you are requested to check and consent to the “Privacy Policy” of such third-party platforms to accept how such third-party platforms handle your Information.</p>

                <p>6. WHAT ARE YOUR PRIVACY RIGHTS?</p>
                <p>6.1. In some regions (like the EEA, UK, and Canada), you have certain rights under applicable data protection laws. These may include the right</p>
                <p>
                    <p>to request access and obtain a copy of your personal information,</p>
                    <p>to request rectification or erasure;</p>
                    <p>to restrict the processing of your personal information; and</p>
                    <p>if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information.</p>
                </p>
                <p>6.2. You can make such a request by contacting us by using the contact details provided in this Privacy Policy. We will consider and act upon any request in accordance with applicable data protection laws.</p>
                <p>6.3. If you are located in the EEA or UK or any other country or region and you believe we are unlawfully processing your personal information, you have the choice exercise your rights as per the applicable laws, rules and regulations.</p>
                <p>6.4. Withdrawing your consent: If we are relying on your consent to process your personal information, which may be express and / or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in this Privacy Policy. However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your information conducted in reliance on lawful processing grounds other than Consent.</p>
                <p>6.5. Opting out of marketing and promotional communications: You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, replying 'STOP' or 'UNSUBSCRIBE' to the SMS messages that we send. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.</p>
                <p>6.6. Account Information If you would at any time like to review or change the information in your account or terminate your account, you can:</p>
                <p>
                    <p>Contact us using the contact information provided in this Privacy Policy.</p>
                    <p>Log in to your account settings and update your user account.</p>
                </p>
                <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and / or comply with applicable legal requirements.</p>
                <p>7. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</p>
                <p>7.1. Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned but will often include your name, email address, and profile picture, as well as other information you choose to make public on such a social media platform.</p>
                <p>7.2. We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice/policy to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.</p>

                <p>8. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</p>
                <p>8.1. Our servers are located in India. If you are accessing our Services from outside India, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information as per the Privacy Policies of this Privacy Policy, in India, and other countries.</p>
                <p>8.2. If you are a resident in the European Economic Area (EEA) or United Kingdom (UK), then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. However, we will take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law.</p>

                <p>9. DATA RETENTION</p>
                <p>9.1. We will retain your Information for as long as your Account is active or as needed to provide our Services. We shall retain and use the Information collected by us as necessary to comply with our legal obligations, resolve disputes or for other Legitimate Purposes. If you cancel/deactivate/unsubscribe your account with us, we are not under any obligation to retain your Information. However, may retain the Information pertaining to the User for the maximum period permitted under the law of the land from the date of deactivation of User’s Account.</p>
                <p>9.2. When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>

                <p>10. DATA PROTECTION RIGHTS</p>
                <p>10.1. You have certain rights available to you when it comes to your Personal Information. Subject to any exemptions provided by the applicable laws, you have the following rights:</p>
                <p>
                    <p>Rectifying, correcting, updating, and removing Your information: You can access, edit, modify and/or update your Personal Information by logging into your user profile or you can write to us via email in case you wish to exercise this right.</p>
                    <p>Accessing and updating or deleting Your information: Our Services and related documentation on our Platform provide you with the ability to access, update and delete certain Personal Information from your Account, if any. We will provide you with information about whether we hold any of your Personal Information upon request. We will respond to such requests within a reasonable timeframe. Please note, however, that we may need to retain certain information for record-keeping purposes, to complete our Services and related obligations to you or to comply with our legal obligations.</p>
                    <p>Object or restrict processing of Your information: You have the right to: (i) object to our processing of your Personal Information; and/or (ii) request that we restrict the processing of your Personal Information.</p>
                    <p>Portability: You shall have the right to request us to transfer Your Personal Information to another controller, or directly to you, in a structured, commonly used and machine-readable format.</p>
                </p>
                <p>10.2. In order to exercise these rights, please contact us on team@immverse.ai</p>

                <p>11. SECURITY MEASURES AND SAFEGUARDS</p>
                <p>11.1. You agree and accept that your Information may be stored in third-party cloud service infrastructure providers. While all reasonable attempts have been taken from our end to ensure the safe and secure storage of your data, we shall not be liable for any data breach on the part of the third-party cloud service infrastructure provider that was beyond our control.</p>
                <p>11.2. In addition to the security measures put in place by the third-party cloud service infrastructure provider for safe and secure storage of your Information, we use certain physical, managerial, technical, or operational safeguards as per industry standards and established best practices to protect the Information we collect.</p>
                <p>11.3. We use reasonable security practices and procedures and use secure servers as mandated under applicable laws for the protection of your Information. We review our Information collection, storage, and processing practices, including physical security measures to guard against unauthorized access to systems. However, as effective as these measures are, no security system is impenetrable. We cannot guarantee the security of our database, nor can we guarantee that the Information you supply will not be intercepted while being transmitted to us over the internet.</p>
                <p>11.4. However, since the internet is not a 100% secure environment, we on a best effort basis ensure the security of any information the User transmits to us and that the information may not be accessed, disclosed, altered, or destroyed by a breach of any of our physical, technical, or managerial safeguards. Please note that e-mails and other communications the User sends to us through our Platform are not encrypted, and we strongly advise the User not to communicate any confidential information through these means.</p>
                <p>11.5. Your information will not be in readable format except in the following cases:</p>
                <p>
                    <p>It is necessary that we view specific messages, files, or other data to provide Services;</p>
                    <p>It is necessary for security purposes (for example, investigating a bug or abuse);</p>
                    <p>It is necessary to comply with applicable law; or</p>
                    <p>The data (including derivations) is aggregated and used for internal operations in accordance with applicable privacy and other jurisdictional legal requirements.</p>
                </p>

                <p>12. DO NOT SELL</p>
                <p>We do not sell your Personal Data. We do not use, transfer, or sell your Information to third parties like advertising platforms, data brokers, or any information resellers. We do not enable serving ads, including retargeting, personalized or interest-based advertising or activities determining the creditworthiness or for lending purposes and do not transfer, sell, share your Information with any third party who is engaged in the same.</p>

                <p>13. DO NOT TRACK</p>
                <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (‘DNT’) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. Our Service currently does not respond to “Do Not Track” (DNT) signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>

                <p>14. UPDATES TO THIS PRIVACY POLICY</p>
                <p>We may change the data privacy practices and update this Privacy Policy as and when the need arises, and the same will be made available on the Platform. But our commitment to protect your privacy will continue to remain. We suggest that you regularly check this Privacy Policy to apprise yourself of any updates. Your continued use of Platform and Services or provision of Information thereafter will imply your unconditional acceptance of such updates to this Privacy Policy. Further, we retain the right at any time to deny or suspend access to all or any part of the service to anyone who we reasonably believe has violated any provision of this Privacy Policy.</p>

                <p>15. COOKIES</p>
                <p>15.1. From time to time, we may use a standard technology called a “cookie”. A cookie is a small text file that is placed on a computer or other device and is used to identify the user or device and to collect information. Cookies are typically assigned to one of four categories, depending on their function and intended purpose: necessary cookies, performance cookies, functional cookies, and cookies for marketing purposes. A cookie cannot retrieve any other data from your hard drive, pass on computer viruses, or capture your e-mail address. Currently, websites use cookies to enhance the user’s visit; in general, cookies can securely store a user’s ID and password, personalize home pages, and identify which parts of a site have been visited. It is possible to set your browser to inform you when a cookie is being placed. This way you have the opportunity to decide whether to accept the cookie. By showing how and when our visitors use this website, this information can help us to improve our site on a continual basis. Cookies tell us nothing about you personally unless you specifically give us additional information. We do not merge or associate our cookie information with any personally identifiable information obtained by us or from third parties.</p>
                <p>15.2. We may use cookies to: (i) count your number of visits to the website; (ii) accumulate anonymous, aggregate, statistical information on website usage; (iii) providing the appropriate content according to your need or viewing history; and (iv) save your password (only on allowing doing so by you) so you don’t have to re-enter it each time you visit our sites. These cookies are necessary for proper functioning of the website, such as displaying content, logging in, validating your session, responding to your request for services, and other functions. Most web browsers can be set to disable the use of cookies. You can also disable cookies. However, if you disable these cookies, you may not be able to access features on our website correctly or at all. By modifying your browser preferences, you can accept or reject all cookies, or request a notification when a cookie is set.</p>
                <p>15.3. Our use of tracking technologies and Technical Information allows us to improve Platform and your experience of Platform and Services. We may also analyze Technical Information that does not contain Sensitive Personal Information for trends and statistics.</p>

                <p>16. CHILDREN</p>
                <p>16.1. We do not provide services directly to children or proactively collect their personal information. Parents or guardians may authorize children to use our website provided they assume all responsibility and legal liability for the conduct of such child including, without limitation, monitoring the child’s access and use of the Platform.</p>
                <p>16.2. If we learn that personal data of a child has been collected without verifiable parental consent, then we may take the appropriate steps to delete such information. However, if you discover your child has submitted his/her data to the Company, you may request to have such data deleted from the Company’s database by sending an e-mail request. Upon receiving the request, the Company shall ensure to delete such information from its database.</p>

                <p>17. GOVERNING LAW AND JURISDICTION</p>
                <p>This Privacy Policy shall be governed by the laws of India; and the Courts of Nagpur (India) shall have the exclusive jurisdiction to try any dispute arising thereof.</p>
                <p>18. ADDRESSING POLICY VIOLATIONS. If we identify violations of our Usage policies, we may ask the customer/you to make necessary changes. Repeated or serious violations may result in further action, including suspending or terminating the customer’s/your account.</p>
                <p>19. THE FOLLOWING SECTION IS APPLICABLE TO RESIDENTS OF CALIFORNIA, UNITED STATE OF AMERICA.</p>

                <p>19.1. “Personal Information” has the meaning given in the California Consumer Privacy Act of 2018 (“CCPA”) but does not include information exempted from the scope of the CCPA.</p>

                <p>19.2. California’s “Shine the Light” law (Civil Code section 1798.83) also permits California residents once per calendar year to request and receive information about a business’ disclosure of certain categories of Personal Information to other companies for direct marketing purposes. If you are a California resident and would like a copy of this notice, please submit a written request to us via email at team@immverse.ai</p>

                <p>19.3. To exercise all the rights as per law, you must put the statement “Your California Privacy Rights” in your request and include your name, street address, city, state, and ZIP code. We are not responsible for notices that are not labeled or sent properly, or do not have complete information.</p>

                <p>20. MISCELLANEOUS</p>

                <p>ENFORCEABILITY OF THE REMAINDER OF THIS PRIVACY POLICY. THIS PRIVACY POLICY DOES NOT APPLY TO ANY INFORMATION OTHER THAN THE INFORMATION COLLECTED BY US THROUGH THE PLATFORM. THIS PRIVACY POLICY SHALL BE INAPPLICABLE TO ANY UNSOLICITED INFORMATION YOU PROVIDE US THROUGH THE PLATFORM OR THROUGH ANY OTHER MEANS. ALL UNSOLICITED INFORMATION SHALL BE DEEMED TO BE NON-CONFIDENTIAL AND WE SHALL BE FREE TO USE AND / OR DISCLOSE SUCH UNSOLICITED INFORMATION WITHOUT ANY LIMITATIONS. THE RIGHTS AND REMEDIES AVAILABLE UNDER THIS POLICY MAY BE EXERCISED AS OFTEN AS NECESSARY AND ARE CUMULATIVE AND NOT EXCLUSIVE OF RIGHTS OR REMEDIES PROVIDED BY LAW. RIGHTS UNDER THIS POLICY MAY BE WAIVED ONLY IN WRITING. DELAY IN EXERCISING OR NON-EXERCISE OF ANY SUCH RIGHT OR REMEDY DOES NOT CONSTITUTE A WAIVER OF THAT RIGHT OR REMEDY, OR ANY OTHER RIGHT OR REMEDY.</p>

                <p>21. GRIEVANCE OFFICER</p>

                <p>21.1. In furtherance of the applicable laws including but not limited to Information Technology Act, 2000 (“IT Act”) and the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021 (“Intermediary Guidelines”) a grievance officer is appointed to ensure compliance with the IT Act and the Intermediary guidelines. Name: Abhijeet Khandagale Email: grievance@immverse.ai</p>

                <p>21.2. The grievance officer shall revert to every complaint within 24 hours of receipt of the complaint. Further, the Company shall take the best possible efforts to redress the complaint within 15 days of receipt of the complaint. Any suggestions by the Company regarding the use of the Services shall not be construed as a warranty.</p>

                <p>QUESTIONS OR CONCERNS OR COMMENTS?</p>

                <p>Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns regarding this Privacy Policy, please contact us at team@immverse.ai and by sending your queries, concerns, or comments to:</p>

                <p>IMMVERSE INNOVATIONS PRIVATE LIMITED New Verma Layout, Ambazari, Nagpur, Maharashtra 440033, India.</p>

            </div>
        </>
    );
};

export default PrivacyPolicyDetails;
