import React, { useState, useEffect, useRef } from "react";
import ellipse3 from "../../assets/About/ellipse3.svg";
import ellipse4 from "../../assets/About/ellipse4.svg";
import EducationContactform from "../educationcontactcomponent";
import macbook from "../../assets/About/mackbook.png";
import video from "../../assets/FellowShip/Fellowship-Video.mp4";
import { useNavigate } from "react-router-dom";

const FellowshipAboutComponent = () => {
  const [hasPlayedVideo, setHasPlayedVideo] = useState(false);
  const navigate = useNavigate();

  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [userInteracted, setUserInteracted] = useState(false);
  const handleVideoClick = () => {
    setUserInteracted(true);

    if (!isPlaying) {
      videoRef.current.play().catch((error) => {
        console.error("Failed to play the video:", error.message);
      });
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    const hasPlayedVideo = localStorage.getItem("hasPlayedVideo");
    if (!hasPlayedVideo) {
      localStorage.setItem("hasPlayedVideo", true);
    }
  }, []);

  const openFellowShip = () => {
    navigate(`./apply/Richard-Feynman-fellowship`);
  };
  return (
    <>
      <div className="w-full flex justify-center items-center mt-8 sm:mt-4 md:mt-0 sm:mb-8 md:mb-12 xl:mb-48">
        <div className="ellipse2  top-[-25rem] absolute sm:top-4 right-0 z-[-1]">
          <img src={ellipse3} alt="" className="w-90" />
        </div>
        <div className="ellipse absolute top-[-25rem] sm:!top-4 left-0 z-[-1]">
          <img src={ellipse4} alt="" className="w-90" />
        </div>
        <div className="w-4/5 flex-col flex sm:flex-row gap-4 mt-80 mb-64 sm:!mb-0 sm:!mt-12 md:!mt-20 lg:!mt-36 xl:!mt-4">
          <div className="w-full sm:w-1/2">
            <div className="flex flex-1 items-center justify-center">
              <img
                src={macbook}
                className="relative top-[28rem]  sm:!top-0 right-[0.8rem]  sm:mr-0 sm:mt-4 ml-8 sm:!ml-0"
              />

              <video
                ref={videoRef}
                width="100%"
                // className="h-[9rem] left-[16%] top-[31rem] w-[62%] absolute sm:!h-[10rem]  sm:!top-[6rem] sm:left-[52%] sm:w-[31%] md:!top-[9rem] md:!w-[32%] md:!h-[11rem] md:left-[52%] lg:w-[32%] lg:!h-[12rem] lg:!left-[52%] lg:!top-[9rem]  xl:w-[32%] xl:!h-[16rem] xl:!left-[52%] xl:!top-[8rem]   2xl:w-[30%] 2xl:!h-[19rem] 2xl:left-[14%] 2xl:!top-[8rem]"
                height="100%"
                className="absolute w-[62%] left-[21%] sm:left-[12.4%] sm:w-[30%] md:left-[13%] lg:left-[13.5%] mt-[55rem] sm:!mt-[-0.3rem] md:!mt-0 "
                controls
                autoPlay={false}
                onClick={handleVideoClick}
                loading="lazy"
              >
                <source src={video} type="video/mp4" />
                Your browser does not support the video
              </video>
            </div>
          </div>
          <div className="text-[#c3c1c7] w-full sm:w-1/2  mt-[-17rem] sm:mt-0 md:!mt-[-1rem] lg:!mt-0  xl:mt-[1rem]">
            <h1 className="text-[1.7rem] sm:text-[1.5rem] md:text-[1.6rem] lg:text-[2rem] xl:text-5xl">
              <span className="text-pink-700  font-bold">
                The Richard Feynman&nbsp;
              </span>
              AI Fellowship
            </h1>
            <p className="pt-8 sm:pt-0 md:pt-0 text-sm sm:text-[8px] md:text-[9px] lg:text-[12px] xl:text-sm xl:pt-2 ">
              The Fellowship is a one-year program for tech enthusiasts who want
              to create a Ding in the AI world and people who want to build new
              things and change the world. The fellowship bestows a stipend, but
              the most valuable possession that you take away for a lifetime is
              becoming a contributor to cutting-edge technologies like building
              large language models (LLMs), voice cloning capabilities, GAN's,
              and other generative AI models.
            </p>

            <p className=" rounded-xl text-[#c3c1c7] mt-4 text-sm sm:text-[8px] lg:text-[10px] xl:text-[14px] font-bold  p-2">
              <h1 className="text-pink-700 font-bold">Who?</h1>
              Freshers who have worked on AI/ML projects are demonstrating a
              strong flare for changing the world with AI.&nbsp;&nbsp;
            </p>
            <button
              onClick={openFellowShip}
              className="  bg-gradient-to-tr from-blue-900 to-pink-600 hover:scale-110   text-[#c3c1c7] rounded-3xl p-2 w-32 sm:w-28  lg:w-32 mt-4 sm:!mt-0 md:!mt-0 lg:!mt-0  xl:!mt-2 ml-[5.5rem] sm:ml-0"
            >
              Apply Now
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FellowshipAboutComponent;
