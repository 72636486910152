import React from 'react'
import { useEffect } from 'react';
import Layout from './../components/Layout';
import Blog from '../components/Blog';

const BlogPage = () => {
   useEffect(() => {
     window.scrollTo(0, 0);
   }, []);
  return (
    <Layout>
        <Blog/>
    </Layout>
  )
}

export default BlogPage