import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import EducationContactform from "../educationcontactcomponent";
import student from "../../assets/FellowShip/pngfind 1.png";
import ellipse3 from "../../assets/About/ellipse3.svg";
import ellipse4 from "../../assets/About/ellipse4.svg";

const FellowshipSelectionComponent = () => {
  const navigate = useNavigate();
  const openFellowShip = () => {
    navigate(`./apply`);
  };

  return (
    <>
      <div className="text-[#c3c1c7] text-4xl pl-8 sm:text-3xl md:text-4xl lg:text-4xl lg:pl-28 xl:text-6xl font-bold pb-8  sm:pl-20 md:pl-20 xl:pl-36 mt-36 lg:!mt-40 xl:!mt-0">
        <span className="text-pink-700 ">Selection </span>Criteria
      </div>
      <div className="w-full flex  flex-col items-center justify-center">
        <div className="flex-col sm:flex-row flex w-4/5 bg-gradient-to-tr from-blue-700 to-pink-700  h-full rounded-xl px-12 md:px-2 xl:px-12">
          <div className="text-[#c3c1c7] mt-4 sm:!mt-0 md:!mt-4 xl:mt-4">
            <h1 className="text-xl sm:!text-[8px] md:!text-[14px] lg:!text-[20px] xl:text-xl font-bold text-white">
              {/* Besides fulfilling the eligibility criteria */}
              Next Steps ?
            </h1>
            <p className="pt-4 sm:!pt-0 md:!pt-4 sm:text-[8px] md:!text-[11px] lg:!text-[13px] xl:text-lg">
              The applicant is required to qualify for the selection process for
              the ImmverseAI Fellowship.
            </p>

            <li className=" sm:text-[8px] md:!text-[11px] lg:!text-[13px] xl:text-lg">
              Application & Assignment submission
            </li>
            <li className=" sm:text-[8px] md:!text-[11px] lg:!text-[13px] xl:text-lg">
              Video interview
            </li>
            <li className=" sm:text-[8px] md:!text-[11px] lg:!text-[13px] xl:text-lg">
              Final selection 
            </li>

            {/* <button
              onClick={openFellowShip}
              className="bg-pink-700  text-[#c3c1c7] rounded-3xl p-2 w-32 sm:w-28  lg:w-32 mt-4 sm:!mt-0 md:!mt-4 lg:!mt-8 xl:!mt-2 ml-8 sm:ml-0"
            >
              Apply Now
            </button> */}
          </div>

          <div className="img mt-0 sm:!mt-[-4rem] md:!mt-[-3rem] lg:!mt-[-4rem] xl:!mt-[-6rem] ">
            <img src={student} />
          </div>
        </div>
      </div>
    </>
  );
};

export default FellowshipSelectionComponent;
