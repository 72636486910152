import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import "../style/latestpostloading.scss"

const LoadingComponent = () => {
  return (
    <div>
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
            <div>
                <div className="flex flex-col gap-12 rounded-lg">
                    {[...Array(3)].map((x, i) =>
                    (
                      <p className="text-xl font-bold capitalize latest-post-skeleton"><Skeleton /></p>
                    )
                    )}
                </div>
            </div>
        </SkeletonTheme>
    </div>
  );
};

export default LoadingComponent;
