import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import "../style/termsofservice.scss";

const TermsOfServiceDetails = () => {

    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Immverse AI is on a mission to immortalize humans by harnessing the power of advanced
        artificial intelligence. We create personalized AI representations, preserving and embodying individual human
        personalities for eternity."
                />
                <meta
                    name="keywords"
                    content="Immverse, Immverse AI, ImmverseAI,Artificial Intelligence, AI, Personalized AI, Immortalizing
        Humans, Human Preservation, Digital Avatar, Virtual Presence"
                />
                <meta name="author" content="Immverse AI" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.immverseai.ai/" />
                <meta
                    property="og:title"
                    content="Immverse AI | Immortalizing Humanity through AI"
                />
                <meta property="og:image" content="/immverseLogo.svg" />
                <meta property="twitter:card" content="/immverseLogo.svg" />
                <meta property="twitter:url" content="https://www.immverseai.ai/" />
                <meta
                    property="twitter:title"
                    content="ImmverseAI - Welcome To ImmverseAI"
                />
                <meta
                    property="twitter:description"
                    content="Immverse AI is on a mission to immortalize humans by harnessing the power of advanced
        artificial intelligence. We create personalized AI representations, preserving and embodying individual human
        personalities for eternity."
                />
                <title>ImmverseAI -Terms Of Service Details</title>
            </Helmet>
            <div>
                <p>
                    These Terms of Service (“Terms”) represent the complete legal agreement between you (“You” or “User”) and Immverse Innovations Private Limited including its affiliates, directors, officers, employees, agents and representatives (“Immverse AI”, “we”, “us” or “our”) that governs your access to and use of the immverse.ai website, including all subdomains, the immverse.ai mobile applications, immverse bots, application programming interfaces (APIs), widgets, buttons, tools, plugins, codes, software, products and services made available on or through the website or mobile applications (collectively, the “Services”). These Terms constitute an electronic record in accordance with the provisions of the Information Technology Act, 2000 and the Information Technology (Intermediaries guidelines) Rules, 2011 thereunder, as amended from time to time.
                </p>

                <p>
                    Note: These Terms contain an arbitration clause and class action waiver. By agreeing to these Terms, you agree (a) to resolve all disputes with us through binding individual arbitration, which means that you waive any right to have those disputes decided by a judge or jury, and (b) that you waive your right to participate in class actions, class arbitrations, or representative actions. You have the right to opt-out of arbitration as explained below.
                </p>

                <p>
                    1. Acceptance of Terms
                </p>

                <p>
                    1.1 By accessing or using any Services, registering for an account, clicking to accept these Terms, or downloading applications, you agree to be legally bound by these Terms as they may be modified and updated by Immverse AI from time to time. If you do not agree to these Terms, do not access or use any of the Services.
                </p>

                <p>
                    1.2 You represent and warrant that you are at least 18 years of age, can form legally binding contracts under applicable law, and are not barred from receiving or using any Services under laws applicable to you. If you are accepting these Terms on behalf of any entity or organization, you represent and warrant that you have full legal authority to bind that entity or organization.
                </p>

                <p>
                    2. Changes to Terms
                </p>

                <p>
                    2.1 Immverse AI may modify these Terms at any time by posting the revised Terms on the Immverse AI website or mobile application. Your continued access or use of Services after revisions become effective shall constitute your binding acceptance of the revised Terms.
                </p>

                <p>
                    2.2 You are requested to regularly review the posted Terms. If you do not agree to the modified Terms, you must stop accessing or using the Services immediately. Your only remedy if you do not wish to be bound by any changes would be to cease your use of the Services entirely.
                </p>
                <p>3. Access and Use of Services</p>

                <p>Your Registration Obligations:</p>

                <p>You may be required to submit a registration form in order to access and use certain features of the Services. If you choose to register, you agree to provide and maintain true, accurate, current and complete information about yourself as prompted by the Services’ registration form. Registration data and certain other information about you are governed by our Privacy Policy. If you are under 13 years old OR if you are an EU citizen or resident under 16 years old, you are not authorized to register to use the Services.</p>

                <p>Member Account, Password and Security.</p>

                <p>You are responsible for maintaining the confidentiality of your password and account, if any, and are fully responsible for any and all activities that occur under your password or account. You agree to (a) immediately notify Immverse AI of any unauthorized use of your password or account or any other breach of security, and (b) ensure that you exit from your account at the end of each session when accessing the Services.</p>

                <p>If there is reason to believe that there is likely to be a breach of security or misuse of your account, we may request you to change the password or we may suspend your account without any liability to the Company, for such period of time as we deem appropriate in the circumstances. We shall not be liable for any loss or damage arising from your failure to comply with this provision.</p>

                <p>You acknowledge that your ability to use your account is dependent upon external factors such as internet service providers and internet network availability and the Company cannot guarantee accessibility to the Application at all times. In addition to the disclaimers set forth in the Terms, the Company shall not be liable to you for any damages arising from your inability to log into your account and access the services of the Application at any time. Immverse AI will not be liable for any loss or damage arising from your failure to comply with this paragraph.</p>

                <p>3.1 Your access to and use of Services is subject to your compliance with these Terms, our Privacy Policy, Acceptable Use Policy (“AUP”) and other policies made available on our website or within our mobile applications. You must be at least 18 years old to register for or use Services.</p>

                <p>3.2 Immverse AI grants you a limited, personal, revocable, non-commercial, non-exclusive, non-transferable license to access and use Services solely for your own lawful purposes as permitted by these Terms and applicable laws. We grant you permission to use Services provided all eligibility criteria are met, but Immverse AI retains ultimate control over the operation, availability and continuation of all aspects of Services.</p>

                <p>3.3 Immverse AI may change, limit, modify, suspend or discontinue any aspect of Services at any time with or without notice as required to comply with applicable laws, protect the rights and safety of others, enhance security, prevent fraud or abuse, and/or optimize operation of Services. Services may not be available in all locations or languages. You understand Services may work differently in different jurisdictions owing to local laws.</p>

                <p>3.4 You acknowledge Immverse AI has no obligation to store, maintain or provide copies of any User Content (defined below). We reserve the right to delete or purge accounts that are inactive for an extended period as per our internal policies in order to optimize utilization of resources. Upon any termination of your access, your right to use Services immediately ceases and we may disable or delete your account information along with any associated User Content without liability to you.</p>

                <p>Modifications to Site.</p>

                <p>Immverse AI reserves the right to modify or discontinue, temporarily or permanently, the Services (or any part thereof) with or without notice. You agree that Immverse AI will not be liable to you or to any third party for any modification, suspension or discontinuance of the Services.</p>

                <p>General Practices Regarding Use and Storage.</p>

                <p>You acknowledge that Immverse AI may establish general practices and limits concerning use of the Services, including without limitation the maximum period of time that data or other content will be retained by the Services and the maximum storage space that will be allotted on Immverse AI’s servers on your behalf. You agree that Immverse AI has no responsibility or liability for the deletion or failure to store any data or other content maintained or uploaded to the Services. You acknowledge that Immverse AI reserves the right to terminate accounts that are inactive for an extended period of time. The decision taken by Immverse AI for determining the extended period of time shall be final and binding upon you. You further acknowledge that Immverse AI reserves the right to change these general practices and limits at any time, in its sole discretion, with or without notice.</p>

                <p>4. Your Obligations</p>

                <p>4.1 You are responsible for your access and use of Services. To access certain Services, you may be required to create an account and select a unique username and password. You must provide accurate, current and complete information while registering an account.</p>

                <p>4.2 You are solely and fully responsible for maintaining the confidentiality and security of your login credentials and preventing unauthorized access to your account. You must immediately notify Immverse AI in writing of any unauthorized access or use of your account and promptly alter your username and password if you suspect your credentials have been compromised. Immverse AI shall bear no liability for any losses arising from unauthorized access or use of your account due to no fault of Immverse AI.</p>

                <p>4.3 You are responsible for all use and activity that occurs through your account, whether initiated or performed by you directly or by any third party due to your negligence or with your permission. It is your duty to ensure your account credentials do not become available to any third party. You shall abide by all applicable laws and regulations in your use of any Services.</p>

                <p>4.4 You agree you will not use Services for any commercial purpose nor conduct any unauthorized commercial activity through your account, including any advertising, marketing, spamming, soliciting or recruitment. You shall not sublicense, resell, distribute or facilitate the distribution of any Services to any third party. You shall not use any automated systems, software or means to access, use or copy our website content, prune or harvest data, or misrepresent your identity or account status.</p>

                <p>4.5 You agree not to upload, post, email, transmit, store or otherwise make available any content that:</p>

                <p>(a) violates any law or regulation applicable in India and the jurisdiction where you reside;</p>
                <p>(b) infringes the copyright, patent, trademark, trade secret or other intellectual property rights of any third party;</p>
                <p>(c) you do not have a right to transmit under any law, contractual or fiduciary relationships;</p>
                <p>(d) contains software viruses or any other harmful code designed to interrupt, destroy or limit Services functionality;</p>
                <p>(e) consists of or contains false, deceptive or misleading statements;</p>
                <p>(f) is defamatory, libelous, threatening, harassing, hateful, abusive, pornographic, obscene or otherwise offensive or objectionable;</p>
                <p>(g) promotes discrimination, bigotry, racism, hatred, harassment or harm against any individual or group;</p>
                <p>(h) is violent or threatening or promotes violence or actions that are threatening;</p>
                <p>(i) violates legal rights of others including right of privacy or publicity;</p>
                <p>(j) impersonates any person or entity or contains false information about your identity;</p>
                <p>(k) solicits personal data or information from minors;</p>
                <p>(l) harvests, collects or attempts to collect personal data of others without consent;</p>
                <p>(m) contains unsolicited promotions or advertisements;</p>
                <p>(n) contains links to or facilitates hacking, unlicensed gambling, multi-level marketing schemes, or online piracy;</p>
                <p>(o) interferes with or disrupts Services operations, servers or networks;</p>
                <p>(p) tries to gain unauthorized access to Services computer systems or user accounts;</p>
                <p>(q) engages in any automated use of Services such as bots, scrapers or spiders;</p>
                <p>(r) modifies, disables or circumvents any Services security, technology or protective features;</p>
                <p>(s) manipulates Services metadata or identifiers to disguise Content origin;</p>
                <p>(t) adversely impacts the availability, reliability or proper functioning of Services for other users;</p>
                <p>(u) violates these Terms, AUP, Privacy Policy or other policies applicable to Services.</p>

                <p>4.6 You must immediately cease any such prohibited conduct upon request by Immverse AI failing which your access and license to use Services shall stand suspended or terminated.</p>

                <p>5. User Content</p>

                <p>5.1 “User Content” refers to any content, media or information submitted by you using Services such as text, photos, videos, audio, generative media, conversations, messages, emails, feedback, suggestions, ideas or the like. You are solely responsible for your User Content.</p>

                <p>5.2 By transmitting or uploading any User Content while using Services, you grant Immverse AI a non-exclusive, worldwide, royalty-free, fully sublicenseable, perpetual and irrevocable license to use, copy, display, reproduce, modify, distribute, store and disclose the User Content solely for the purpose of providing and administering Services. If you are uploading another person’s content, you represent you have obtained their consent and have the right to grant this license.</p>

                <p>5.3 You acknowledge Immverse AI may need to process and store User Content on servers located in and outside India and transfer data cross-border to provide Services. You consent to such processing and transfers as required for the operation of Services.</p>

                <p>5.4 You represent and warrant you have all necessary legal rights and permissions to upload or transmit User Content and it does not violate any law or infringe any intellectual property or privacy rights of any third party under Indian laws. If your User Content includes personal or sensitive information, you consent to its collection, use, disclosure and transfer by Immverse AI as outlined in our Privacy Policy.</p>

                <p>5.5 Immverse AI has no obligation to monitor, screen, police, edit or remove any User Content. However, Immverse AI may review, monitor, restrict access to or remove any User Content in whole or in part that Immverse AI considers objectionable, violates Terms or applicable laws, or adversely affects Services or other users. Immverse AI may also report any suspected illegal activity to appropriate law enforcement authorities.</p>

                <p>5.6 User Content is owned by the contributing user. Immverse AI does not claim ownership of any User Content transmitted by you. You shall be solely liable for any Content you upload and compliance with applicable laws. Any use of Services does not confer any proprietary interest or transfer any intellectual property rights to you apart from the licenses granted herein.</p>

                <p>6. Generative Media</p>

                <p>6.1 The Services may allow you to provide prompts or inputs in order to generate creative media content such as images, audio, text, video or the like (“Generative Media”). While you may provide prompts or inputs, you acknowledge Generative Media is newly created content generated by the Services.</p>

                <p>6.2 By using generative features, you grant Immverse AI an unlimited, worldwide license to create, use, reproduce, modify, adapt, publish, translate, distribute, perform and display Generative Media created based on your prompts or inputs provided through the Services. This license shall be perpetual, sublicensable, irrevocable, royalty-free, transferable and fully paid-up.</p>

                <p>6.3 You understand Generative Media may incorporate or be derived from media sourced from third-party licensors. Such third-party content shall remain the sole property of the applicable licensors, subject to licenses granted to Immverse AI.</p>

                <p>6.4 Generative Media should not be used in any manner that violates applicable laws or infringes rights of others. You shall evaluate Generative Media to determine its suitability for your use case. Immverse AI makes no warranties regarding attributes, factual accuracy or quality of Generative Media. You use such media at your own risk.</p>

                <p>6.5 Immverse AI reserves the right to suspend, limit or terminate any Generative Media features within Services at any time and for any reason, including quality, safety, compliance or liability concerns, without any liability to you.</p>

                <p>7. Feedback</p>

                <p>7.1 If you provide any suggestions, ideas, enhancements, modifications, feedback, recommendations or other information relating to Services (“Feedback”), you grant Immverse AI the right to freely use, disclose, reproduce, modify, license, transfer and distribute the Feedback without payment, attribution or compensation. We shall be entitled to use Feedback for any purpose without restriction or remuneration of any kind to you.</p>

                <p>7.2 You agree you will not submit Feedback that includes any confidential or proprietary information of any third party. Any Feedback provided by you shall be deemed non-confidential. Our rights shall survive termination of these Terms.</p>
                <p>8. Third Party Services</p>

                <p>8.1 The Services may contain links, integrations, or content from websites, platforms, applications, or networks operated by third parties (“Third Party Services”). Third Party Services are not under Immverse AI’s control, and Immverse AI does not endorse their safety, conduct, information, or promotions.</p>

                <p>8.2 Your use of or interactions with any Third Party Services is solely between you and the applicable third parties. Immverse AI shall not be responsible or liable, directly or indirectly, for any loss, damage, security issues, or other negative consequences arising from your use of or reliance on any Third Party Services.</p>

                <p>9. Ownership and Copyrights</p>

                <p>9.1 The Services and all associated intellectual property rights, proprietary content, and technology are the exclusive property of Immverse AI and its licensors. Services may include software, documents, images, graphics, and other material owned or licensed by Immverse AI. Except as explicitly permitted under these Terms, nothing shall grant any express or implied license to use, copy, or download any content on the Services.</p>

                <p>9.2 The trademarks, logos, service marks, and trade names displayed on the Services are registered and unregistered marks of Immverse AI and third parties. No license is granted to use such marks in any manner without Immverse AI’s prior written consent. All rights, title, and interest in Immverse AI’s intellectual property rights shall remain solely with Immverse AI.</p>

                <p>9.3 Immverse AI respects intellectual property rights of others and prohibits users from violating copyright laws. Per our Copyright Policy, you may notify Immverse AI of any copyright infringements or violations reported through the Services. If a counter notice is received, Immverse AI may restore removed content after receipt of a valid counter notice by you.</p>

                <p>10. Privacy</p>

                <p>10.1 Your access and use of Services are subject to Immverse AI’s Privacy Policy, which is available on our website and incorporated into these Terms. You confirm that you have read, understood, and consent to the collection, storage, processing, sharing, and transfer of your data as outlined under our Privacy Policy.</p>

                <p>10.2 You may choose to disable the collection of certain User data through Privacy controls available within Services settings. Opting out may impact the provision of Services. You permit Immverse AI to collect aggregated non-identifying data regarding Services access and use. Such aggregated data does not identify you individually.</p>

                <p>10.3 If you access Services from a shared device or authorized others to access your account, any collected information will be visible to all such users. You are responsible for providing notice to and obtaining consent from such shared users as required under applicable privacy laws.</p>

                <p>11. Confidentiality</p>

                <p>11.1 You agree not to disclose any Confidential Information provided by Immverse AI or obtained during your access to Services to any third party without Immverse AI’s explicit prior written consent. Confidential Information shall include but not be limited to: (a) the Services source code, technology, unpublished documents, and non-public technical information; (b) Immverse AI business or technical plans, strategies, methods, and projections; (c) Immverse AI research and development, prototypes, software or hardware products/services not yet commercially released.</p>

                <p>11.2 Your confidentiality obligations shall survive expiration or termination of these Terms. However, information which: (a) entered the public domain without a breach of confidentiality, (b) proven to be independently developed by you, or (c) produced in compliance with applicable law or court order; shall not be considered Confidential Information.</p>

                <p>12. Disclaimers</p>

                <p>12.1 Services along with any associated data, information, content, or material are provided on an "AS IS" and “AS AVAILABLE” basis without any warranties or conditions of any kind express or implied.</p>

                <p>12.2 Immverse AI expressly disclaims any representations, conditions, or warranties of any kind whether statutory, express, or implied relating to Services and associated data, information, content and material, including:</p>

                <p>(a) Any implied warranty merchantability, fitness for a particular purpose, accuracy, timeliness, or quality;</p>

                <p>(b) Any warranty that Services or associated material will be uninterrupted, virus-free, or error-free or that errors will be corrected or meet your requirements;</p>

                <p>(c) Any express or implied warranty regarding non-infringement, information accuracy, content quality, availability, security, or performance.</p>

                <p>12.3 Immverse AI cannot guarantee Services will meet your expectations, be timely, secure, or error-free. We do not endorse opinions, information, statements, or material displayed or distributed through Services. You shall bear any and all risks associated with your access and use of Services.</p>

                <p>13. Liability Limitation</p>

                <p>13.1 UNDER NO CIRCUMSTANCES WILL IMMVERSE AI, ITS OFFICERS, DIRECTORS, EMPLOYEES, PARTNERS, OR LICEN BE LIABLE TO YOU FOR ANY LOSS OF PROFITS OR REVENUE, LOSS OF BUSINESS, LOSS OF GOODWILL, LOSS OF DATA, BUSINESS INTERRUPTION OR INDIRECT, SPECIAL, INCIDENTAL, EXEMPLARY, CONSEQUENTIAL OR PUNITIVE DAMAGES ARISING OUT OF OR RELATED TO YOUR USE OF SERVICES OR INABILITY TO ACCESS OR USE SERVICES, EVEN IF ADVISED IN ADVANCE OF SUCH POSSIBILITY AND IRRESPECTIVE OF WHETHER SUCH DAMAGES ARE FORESEEABLE OR WHETHER IMMVERSE AI HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES IN ADVANCE.</p>

                <p>13.2 WITHOUT LIMITING THE FOREGOING, IN NO EVENT WILL IMMVERSE AI’S AGGREGATE LIABILITY FOR ANY AND ALL CLAIMS UNDER THESE TERMS EXCEED INR 1000 (INDIAN RUPEES ONE THOUSAND). THE EXISTENCE OF ONE OR MORE CLAIMS SHALL NOT INCREASE THIS MAXIMUM LIABILITY AMOUNT.</p>

                <p>13.3 THE ABOVE LIMITATIONS SHALL APPLY REGARDLESS OF THE FORM OR THEORY OF ACTION AND SHALL APPLY TO THE GREATEST EXTENT PERMITTED UNDER APPLICABLE LAW, EVEN IF CERTAIN REMEDIES FAIL. SOME JURISDICTIONS MAY NOT ALLOW CERTAIN LIMITATIONS, SO SOME OF THESE PROVISIONS MAY NOT FULLY APPLY.</p>

                <p>14. Indemnity</p>

                <p>14.1 You agree to fully indemnify, defend and hold harmless Immverse AI and its officers, directors, employees, partners, contractors, agents and representatives from any claims, demands, actions, losses, liabilities, penalties, fines, damages, judgments, settlements, costs and expenses (including reasonable attorneys' fees) arising from or relating to: (a) your access and use of Services; (b) any User Content you upload or transmit through Services; (c) your violation of these Terms; or (d) your violation of any rights of any third party.</p>

                <p>14.2 Immverse AI reserves the right, at your expense, to assume exclusive defense and control in any legal action or claim that falls under this indemnity clause. You agree to cooperate and assist Immverse AI upon request in the defense of any such action.</p>

                <p>15. Governing Law and Jurisdiction</p>

                <p>15.1 These Terms shall be governed and interpreted in accordance with the substantive laws of India without regard to its conflicts of law provisions. The courts at Nagpur, India shall have exclusive jurisdiction over any disputes arising in relation to these Terms.</p>

                <p>15.2 You hereby expressly consent to the exclusive jurisdiction and venue of courts in Nagpur, India in all disputes arising out of or relating to access or use of Services or relating to these Terms or any breach thereof.</p>

                <p>16. Termination</p>

                <p>16.1 Immverse AI reserves the right to terminate, suspend or restrict your access and use of the Services, at any time and for any reason, at our sole discretion, with or without notice, effective immediately. Grounds for termination may include extended periods of inactivity, violation of these Terms, legal or regulatory reasons, non-compliance with applicable policies, or other actions that Immverse AI deems inappropriate or harmful.</p>

                <p>16.2 Upon any suspension or termination, your license to use the Services shall automatically stand revoked. In case of terminations, Immverse AI may deactivate or delete your account and all associated data without liability to you. We shall not be obligated to return any of your User Content or data nor respond to further inquiries.</p>

                <p>16.3 Any limitations of liability, disclaimers or indemnity obligations by either party shall survive termination of these Terms.</p>

                <p>17. Modifications to Terms</p>

                <p>17.1 Immverse AI may modify, replace or update these Terms at any time by posting the revised Terms on the Immverse AI website. By continuing to access the Services after revised Terms are effective, you agree to be bound by the updated Terms.</p>

                <p>17.2 It shall be your responsibility to regularly check the website for any changes to the Terms. Immverse AI may also notify users of any significant changes to Terms via email or notifications within the Services. Changes apply prospectively once posted or from the designated effective date. Your continued use of Services following such notification constitutes acceptance of revised Terms. If you do not wish to be bound by revised Terms, you must immediately cease using all Services.</p>

                <p>18. General Provisions</p>

                <p>18.1 Severability: If any part of these Terms is determined by a court of competent jurisdiction to be invalid, prohibited, or unenforceable, the remaining provisions shall continue in full force without being impaired or invalidated in any way.</p>

                <p>18.2 Assignment: You shall not assign, transfer or delegate these Terms and your obligations without Immverse AI’s prior written consent. Immverse AI may freely assign these Terms without your approval. Subject to the foregoing, these Terms shall be binding upon the respective successors and permitted assigns of each party.</p>

                <p>18.3 Non-Waiver: Any failure by Immverse AI to enforce strict performance or exercise its rights under these Terms shall not be construed as a waiver of those rights or any other rights under these Terms.</p>

                <p>18.4 Relationship: These Terms do not create any agency, partnership or joint venture between the parties. No third party beneficiary rights are created for any entity not a party hereto.</p>

                <p>18.5 Notices: All notices and other communications provided under these Terms, including those regarding modifications, will be made through notifications or contact mechanisms within the Services, through registered or email communication to your registered email address, or general notice posted on Immverse AI website. Communications made through email or Services notifications will be deemed received 24 hours after transmission.</p>

                <p>18.6 Entire Agreement: These Terms along with the Privacy Policy and other policies referenced herein constitute the complete agreement between you and Immverse AI relating to use of Services. These Terms supersede any prior oral or written agreements on the same subject matter.</p>

                <p>18.7 Confidentiality</p>

                <p>All aspects of the arbitration proceeding, and any ruling, decision, or award by the arbitrator, will be strictly confidential for the benefit of all parties.</p>

                <p>18.8 Severance.</p>

                <p>If any provision of these Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties’ intentions as reflected in the provision, and the other provisions of these Terms remain in full force and effect.</p>

                <p>18.9 Compliance with Local Laws</p>

                <p>Immverse AI makes no representation or warranty that the content available on this Site or the Services found at this Site are appropriate in every country or jurisdiction, and access to this Site or the Services found at this Site from countries or jurisdictions where its content is illegal is prohibited. Users who choose to access this Site or the Services found at this Site are responsible for compliance with all local laws, rules, and regulations.</p>

                <p>19. Contact Us</p>

                <p>19.1 If you have any questions about these Terms or your use of the Services, please contact us at: terms@immverse.ai or Immverse Innovations Private Limited, Ambazari Road, Nagpur 440033 India.</p>

                <p>DISPUTE RESOLUTION BY BINDING ARBITRATION.</p>

                <p>This section affects your rights so please read it carefully. This Dispute Resolution by Binding Arbitration section of the Terms is referred to in these Terms as the “Arbitration Agreement.”</p>

                <p>Agreement to Arbitrate</p>

                <p>You agree that any and all disputes or claims that have arisen or may arise between you and Immverse AI, whether arising out of or relating to these Terms (including any alleged breach thereof), the Website or Services, any aspect of the relationship or transactions between us, shall be resolved exclusively through final and binding arbitration, rather than a court, in accordance with the terms of this Arbitration Agreement. You agree that, by entering into these Terms, you and Immverse AI are each waiving the right to a trial by jury or to participate in a class action. Your rights will be determined by a neutral arbitrator, not a judge or jury.</p>

                <p>Prohibition of Class and Representative Actions and Non-Individualized Relief</p>

                <p>You and Immverse AI agree that each of us may bring claims against the other only on an individual basis and not as a plaintiff or class member in any purported class or representative action or proceeding. Unless both You and Immverse AI agree in writing otherwise, the arbitrator may not consolidate or join more than one person’s or party’s claims and may not otherwise preside over any form of a consolidated, representative, or class proceeding. Also, the arbitrator may award relief (including monetary, injunctive, and declaratory relief) only in favor of the individual party seeking relief and only to the extent necessary to provide relief necessitated by that party’s individual claim(s).</p>

                <p>1.1. Pre-Arbitration Dispute Resolution</p>

                <p>Immverse AI is always interested in resolving disputes amicably and fairly, and so if you have concerns, we strongly encourage you to first email us about them at support@immverse.ai. If such efforts prove unsuccessful, a party who intends to seek arbitration must first send to the other, by certified mail, a written Notice of Dispute (“Notice”).</p>
                <p>The Notice to Immverse AI should be sent to Immverse Innovations Inc. 3, Verma Layout, Near Ambazari Garden Nagpur 440033 (“Notice Address”). The Notice must (i) describe the nature and basis of the claim or dispute and (ii) set forth the specific relief sought. If Immverse AI and you do not resolve the claim within sixty (60) calendar days after the Notice is received, you or Immverse AI may commence an arbitration proceeding.</p>

                <p>During the arbitration, the amount of any settlement offer made by Immverse AI or you shall not be disclosed to the arbitrator until after the arbitrator determines the amount, if any, to which you or Immverse AI is entitled.</p>

            </div>
        </>
    );
};

export default TermsOfServiceDetails;
