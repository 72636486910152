import React from "react";
import FooterLogo from "../assets/Nav/immverse-logo-tm.svg";
import "../style/footer.scss";
import facebook from "../assets/Footer/facebook.svg";
import linkedin from "../assets/Footer/linkedin.svg";
import twitter from "../assets/Footer/twitterx.svg";
import insta from "../assets/Footer/instagram.svg";
import threads from "../assets/Footer/threads.svg";
import youtube from "../assets/Footer/youtube.svg";
import { Link } from "react-router-dom";
import Hero from "./Hero";
const Footer = (props) => {
  return (
    <div className="footer-section ">
      <div className="footer-content">
        <img src={FooterLogo} alt="" />
        <p className="text-white">Immortalizing
        Humans</p>
        <div className="social-media">
          <Link
            to="https://www.facebook.com/ImmverseAI"
            target="_blank"
            className="facebook-logo"
          >
            <img src={facebook} alt="" />
          </Link>
          <Link to="https://twitter.com/ImmverseAI" target="_blank">
            <img src={twitter} alt="" />
          </Link>
          <Link to="https://www.instagram.com/immverseai/" target="_blank">
            <img src={insta} alt="" />
          </Link>
          <Link
            to="https://www.linkedin.com/company/immverse-ai/"
            target="_blank"
          >
            <img src={linkedin} alt="" />
          </Link>
          <Link to="https://www.threads.net/@immverseai" target="_blank">
            <img src={threads} alt="" />
          </Link>
          <Link to="https://www.youtube.com/@ImmverseAI" target="_blank">
            <img src={youtube} alt="" />
          </Link>
        </div>
        <p>
          <Link to="/termsOfService">Terms Of Service</Link> |{" "}
          <Link to="/privacyPolicy">Privacy Policy</Link>
        </p>
        <hr />
        <div className="copyright">
          <p className="white ">
            Disclaimer: Act of fiction, content is AI generated, exercise
            caution, ImmverseAI owes no responsibility.
          </p>
          <p>Copyright ©2023 ImmverseAI. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
