import React, { useEffect, useState } from "react";
import "../style/JobDescription.scss";
import job from "../assets/Career/job.svg";
import watch from "../assets/Career/watch.svg";
import location from "../assets/Career/location.svg";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { domainData } from "./Domain";
import Layout from "./Layout";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { Input, Label, TextField } from "react-aria-components";
import JobDescriptionLoadingComponent from "./JobDescriptionLoadingComponent";
import { useNavigate } from "react-router-dom";

const JobDescription = () => {
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();

  const [clicked, setClicked] = useState(false);

  const handleNavigate = () => {
    setClicked(true);
    navigate(-1); // Assuming navigate is a function available in your component
  };

  const [data, setData] = useState();
  const { jobId } = useParams();

  const [formdata, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    resumeurl: "",
    portfoliourl: "",
  });

  const [resume, setResume] = useState({});

  // const openLink = () => {
  //   const recruitinglink =
  //     "https://recruit.zoho.in/recruit/immverseai/ShowTab.do?module=Home&submodule=Home";
  //   window.open(recruitinglink, "_blank");
  // };

  // useEffect(() => {
  //   const getData = async () => {
  //     let config = {
  //       method: "get",
  //       url: `${domainData.url}/get-all-career`,

  //       headers: {
  //         "x-api-key": "Immverse-API-sam-0987654321",
  //       },
  //     };
  //     window.scrollTo(0, 0);
  //     try {
  //       const response = await axios.request(config);
  //       console.log("jobdescription ", response);
  //       const jobInfo = response.data.careers.data.find(
  //         (item) => String(item.id) === jobId
  //       );

  //       setData(jobInfo);
  //       setLoader(false);
  //     } catch (error) {}
  //   };

  //   getData();
  // }, [jobId]);

  useEffect(() => {
    const getData = async () => {
      let config = {
        method: "get",
        url: `${domainData.url}/get-all-career`,
        headers: {
          "x-api-key": "Immverse-API-sam-0987654321",
        },
      };
      window.scrollTo(0, 0);
      try {
        const response = await axios.request(config);
        const jobInfo = response.data.careers.data.find(
          (item) => String(item.id) === jobId
        );

        setData(jobInfo);
        setLoader(false);
      } catch (error) {}
    };

    getData();
  }, [jobId]);

  const openLink = () => {
    if (data) {
      const recruitinglink = data.recruiting_platform_url;
      window.open(recruitinglink, "_blank");
    }
  };

  useEffect(() => {
    const scrollToSection = () => {
      const sectionId = `job-${jobId}`;
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: "smooth" });
      }
    };

    scrollToSection();
  }, [jobId]);
  return (
    <Layout>
      <div className="jobs-info">
        {loader ? (
          <JobDescriptionLoadingComponent />
        ) : (
          <div key={data.id} className="job-container-data">
            <div className="job-description-">
              <div className="Ai-job">
                <div className="svg-bg">
                  <button
                    className="back-button"
                    id={`job-${jobId}`}
                    onClick={handleNavigate}
                    style={{
                      borderRadius: "50%",
                      paddingBottom: clicked ? "30rem" : 0,
                    }}
                  >
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 60 60"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="59"
                        height="59"
                        rx="29.5"
                        fill="none"
                        stroke="none"
                      />
                      <path
                        d="M24.47 30.07C24.41 29.83 24.38 29.56 24.38 29.26C24.38 28.94 24.41 28.72 24.47 28.6C24.67 28.4 25.62 27.48 27.32 25.84C29.04 24.2 30.09 23.2 30.47 22.84C31.17 22.22 32.14 21.49 33.38 20.65L34.58 21.97C30.78 25.65 28.6 27.74 28.04 28.24C27.5 28.74 27.11 29.08 26.87 29.26C27.51 29.78 27.85 30.06 27.89 30.1L34.58 36.52L33.38 37.84C32.3 37.12 31.44 36.49 30.8 35.95C30.18 35.39 29.01 34.31 27.29 32.71C25.57 31.11 24.63 30.23 24.47 30.07Z"
                        fill="white"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_118_5"
                          x1="-1.02902"
                          y1="30.4918"
                          x2="61.6623"
                          y2="30.4918"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#08081E" />
                          <stop offset="1" stopColor="#1E060F" />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_118_5"
                          x1="60"
                          y1="-1.26865e-05"
                          x2="-0.646827"
                          y2="1.74726"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#C52266" />
                          <stop offset="1" stopColor="#3F42DB" />
                          <stop offset="1" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </button>
                </div>
                <p
                  className="text-white"
                  style={{ paddingTop: "4%", paddingLeft: "3%" }}
                >
                  <span>{data.job_title}</span>
                </p>
              </div>
              <div className="locationwatch-info">
                <div className="location">
                  <img src={watch} alt="" loading="lazy" />
                  <p>{data.region?.region_name}, India</p>
                </div>
                <div className="watch">
                  <img src={location} alt="" loading="lazy" />
                  <p>Full time</p>
                </div>
              </div>
              <h6 style={{ fontWeight: "bolder" }}>Job Description:</h6>
              <p dangerouslySetInnerHTML={{ __html: data.job_description }}></p>

              <br />

              <h6 style={{ fontWeight: "bolder" }}>Key Responsibilities:</h6>
              <p dangerouslySetInnerHTML={{ __html: data.key_skills }}></p>

              <br />
            </div>
          </div>
        )}

        <div className="apply-button">
          <button onClick={openLink}>
            <div
              style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
            >
              Apply Now
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 20 20"
                fill="none"
                className="svg-apply"
                style={{ marginRight: "0.5em" }}
              >
                <path
                  d="M18.3383 7.31637L4.33904 0.314359C3.78656 0.0392893 3.16272 -0.0586374 2.55257 0.0339303C1.94242 0.126498 1.37566 0.405055 0.929552 0.831623C0.483448 1.25819 0.179714 1.81201 0.0597603 2.41757C-0.0601932 3.02313 0.00947168 3.65097 0.259257 4.21548L2.65913 9.58702C2.71358 9.71689 2.74163 9.85631 2.74163 9.99714C2.74163 10.138 2.71358 10.2774 2.65913 10.4073L0.259257 15.7788C0.0559679 16.2356 -0.0299718 16.7361 0.00924744 17.2346C0.0484667 17.7331 0.211602 18.2139 0.483826 18.6333C0.756051 19.0527 1.12874 19.3974 1.56801 19.6361C2.00728 19.8748 2.49922 19.9999 2.99911 20C3.46731 19.9953 3.92855 19.886 4.34903 19.6799L18.3483 12.6779C18.8448 12.428 19.2622 12.045 19.5539 11.5717C19.8456 11.0983 20 10.5532 20 9.99714C20 9.44108 19.8456 8.89597 19.5539 8.4226C19.2622 7.94924 18.8448 7.56625 18.3483 7.31637H18.3383ZM17.4483 10.8874L3.44908 17.8894C3.26526 17.9777 3.05884 18.0077 2.8575 17.9753C2.65617 17.9429 2.46955 17.8497 2.32266 17.7082C2.17577 17.5667 2.07564 17.3837 2.03569 17.1836C1.99574 16.9836 2.01789 16.7761 2.09916 16.589L4.48903 11.2175C4.51996 11.1458 4.54668 11.0723 4.56902 10.9974H11.4586C11.7238 10.9974 11.9782 10.892 12.1657 10.7044C12.3532 10.5169 12.4586 10.2624 12.4586 9.99714C12.4586 9.73184 12.3532 9.47742 12.1657 9.28983C11.9782 9.10223 11.7238 8.99685 11.4586 8.99685H4.56902C4.54668 8.92199 4.51996 8.84851 4.48903 8.77678L2.09916 3.40524C2.01789 3.21815 1.99574 3.01068 2.03569 2.81064C2.07564 2.61061 2.17577 2.42757 2.32266 2.28607C2.46955 2.14458 2.65617 2.05139 2.8575 2.019C3.05884 1.98661 3.26526 2.01658 3.44908 2.10487L17.4483 9.10688C17.6121 9.19082 17.7496 9.31835 17.8456 9.47543C17.9415 9.63251 17.9923 9.81304 17.9923 9.99714C17.9923 10.1812 17.9415 10.3618 17.8456 10.5188C17.7496 10.6759 17.6121 10.8034 17.4483 10.8874Z"
                  fill="white"
                />
              </svg>
            </div>
          </button>
        </div>
      </div>
    </Layout>
  );
};
export default JobDescription;
