import React, { useState } from "react";
import "../style/careergallery.scss";
import image1 from "../assets/Career/img1.jpg";
import image2 from "../assets/Career/img2.jpg";
import image3 from "../assets/Career/img3.jpg";
import image4 from "../assets/Career/img4.jpg";
import { FaChevronLeft, FaChevronRight, FaTimes } from "react-icons/fa";

const CareerImageGalleryComponent = () => {
  const images = [image1, image2, image3, image4];
  const [isGalleryVisible, setGalleryVisible] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const toggleGallery = () => {
    setGalleryVisible(!isGalleryVisible);
  };

  const handleOnImageClick = (index) => {
    setCurrentImageIndex(index);
    toggleGallery(); // Open the gallery on image click
  };

  const closeGallery = () => {
    setGalleryVisible(false);
  };

  const showPreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const showNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="career-section flex flex-col sm:flex-row  gap-10 justify-center ">
      <div className="row1 flex-col gap-8 flex">
        {images.slice(0, 2).map((image, index) => (
          <img
            key={index}
            src={image}
            className="h-[25rem] sm:h-[20rem] sm:w-[22rem] md:w-[30rem] lg:h-[25rem] rounded-2xl border-[#d71d89] border"
            alt={`Career Image ${index + 1}`}
            onClick={() => handleOnImageClick(index)}
          />
        ))}
      </div>
      <div className="row2 flex-col flex gap-8  sm:mt-28">
        {images.slice(2, 4).map((image, index) => (
          <img
            key={index + 2}
            src={image}
            className="h-[25rem] sm:h-[20rem] sm:w-[22rem] md:w-[30rem] lg:h-[25rem]  rounded-2xl border-[#d71d89] border"
            alt={`Career Image ${index + 3}`}
            onClick={() => handleOnImageClick(index + 2)}
          />
        ))}
      </div>

      {isGalleryVisible && (
        <div className="popover w-full h-full bg-black flex justify-center items-center">
          <div className="collage-container h-full w-4/5">
            <button
              className="close-button bg-black text-white absolute top-5 text-3xl right-0"
              onClick={closeGallery}
            >
              <FaTimes />
            </button>
            <button
              className="nav-button previous-button bg-black text-white absolute top-96  text-3xl"
              onClick={showPreviousImage}
            >
              <FaChevronLeft />
            </button>
            <button
              className="nav-button next-button bg-black text-white absolute top-96  text-3xl right-0"
              onClick={showNextImage}
            >
              <FaChevronRight />
            </button>
            <div className="flex items-center justify-center mt-40 sm:mt-16 md:mt-40 h-68 sm:h-100 lg:h-full lg:mt-0 xl:w-full  lg:w-full    ">
              <img
                src={images[currentImageIndex]}
                alt={`Career Image ${currentImageIndex + 1}`}
                className="collage-item w-full h-full sm:h-[70%] lg:h-[90%]  flex items-center justify-center  "
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CareerImageGalleryComponent;
