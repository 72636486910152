import React from "react";
import "../style/pedalogyeducationcomponent.scss";
import "../assets/rectangle.png";
import ellipse from "../assets/About/ellipse3.svg";
import image1 from "../assets/pedalogy-img1.png";
import image2 from "../assets/pedalogy-img2.png";
import image3 from "../assets/pedalogy-img3.png";

const PedalogyEducationSection = () => {
  //   useEffect(() => {
  //     window.scrollTo(0, 0);
  //   }, []);

  return (
    <>
      <div className="discover-section-box-pedalogy sm:pt-12 md:pt-0">
        <div className="discover-section-pedalogy">
          <h1>
            <span>Pedagogy</span>
          </h1>

          <div className="discover-card">
            <div className="cards">
              <div className="card">
                <div className="Ai">
                  <div className="svg-bg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 25 27"
                      fill="none"
                    >
                      <path
                        d="M12.4814 23.8398C18.5566 23.8398 23.4814 18.915 23.4814 12.8398C23.4814 6.76471 18.5566 1.83984 12.4814 1.83984C6.40631 1.83984 1.48145 6.76471 1.48145 12.8398C1.48145 18.915 6.40631 23.8398 12.4814 23.8398Z"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.1095 22.2999C19.8387 23.0706 21.3875 24.1947 22.6562 25.5999M2.30615 25.5999C3.57485 24.1947 5.12361 23.0706 6.85282 22.2999"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.4814 18.3399C15.519 18.3399 17.9814 15.8774 17.9814 12.8399C17.9814 9.80231 15.519 7.33987 12.4814 7.33987C9.44388 7.33987 6.98145 9.80231 6.98145 12.8399C6.98145 15.8774 9.44388 18.3399 12.4814 18.3399Z"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.4814 13.7565C12.9876 13.7565 13.398 13.3461 13.398 12.8398C13.398 12.3336 12.9876 11.9232 12.4814 11.9232C11.9751 11.9232 11.5647 12.3336 11.5647 12.8398C11.5647 13.3461 11.9751 13.7565 12.4814 13.7565Z"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <p className="text-white text-md sm:text-xl">
                    <span>Realistic</span> Digital Interactive Avatar
                  </p>
                </div>
                <div className="description">
                  <img src={image1} className="p-4" loading="lazy"></img>

                  <p>
                    Historical experts that share their unique story in their
                    own way!
                  </p>
                </div>
              </div>
              <div className="card">
                <div className="Ai">
                  <div className="svg-bg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 28 21"
                      fill="none"
                    >
                      <path
                        d="M10.1119 2.12937C11.3077 1.77569 12.5552 1.59723 13.8096 1.60038C18.9643 1.60038 22.4722 4.47532 24.5638 7.00986C25.6115 8.28173 26.1354 8.91537 26.1354 10.8002C26.1354 12.6861 25.6115 13.3198 24.5638 14.5905C22.4722 17.125 18.9643 20 13.8096 20C8.65501 20 5.1471 17.125 3.05542 14.5905C2.00773 13.3209 1.48389 12.685 1.48389 10.8002C1.48389 8.91422 2.00773 8.28058 3.05542 7.00986C3.69434 6.23099 4.40584 5.50637 5.18161 4.84446"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <path
                        d="M17.5073 10.8001C17.5073 11.7151 17.1177 12.5926 16.4242 13.2396C15.7308 13.8866 14.7902 14.25 13.8095 14.25C12.8288 14.25 11.8883 13.8866 11.1949 13.2396C10.5014 12.5926 10.1118 11.7151 10.1118 10.8001C10.1118 9.88514 10.5014 9.00763 11.1949 8.36065C11.8883 7.71366 12.8288 7.35019 13.8095 7.35019C14.7902 7.35019 15.7308 7.71366 16.4242 8.36065C17.1177 9.00763 17.5073 9.88514 17.5073 10.8001Z"
                        stroke="white"
                        strokeWidth="2"
                      />
                    </svg>
                  </div>
                  <p className="text-white text-md sm:text-xl">
                    <span>Enjoy</span> Immverse Experience
                  </p>
                </div>
                <div className="description">
                  <img src={image2} className="p-4" loading="lazy"></img>

                  <p>
                    Truly immersive capabilities to interact in a natural way.
                  </p>
                </div>
              </div>

              <div className="card ">
                <div className="Ai">
                  <div className="svg-bg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 22 22"
                      fill="none"
                    >
                      <path
                        d="M8.77778 3.22222L11 1M6.55556 15.4444L15.4444 6.55556M11 21L13.2222 18.7778M16 18.7778C16.7367 18.7778 17.4433 18.4851 17.9642 17.9642C18.4851 17.4433 18.7778 16.7367 18.7778 16C18.7778 15.2633 18.4851 14.5567 17.9642 14.0358C17.4433 13.5149 16.7367 13.2222 16 13.2222C15.2633 13.2222 14.5567 13.5149 14.0358 14.0358C13.5149 14.5567 13.2222 15.2633 13.2222 16C13.2222 16.7367 13.5149 17.4433 14.0358 17.9642C14.5567 18.4851 15.2633 18.7778 16 18.7778ZM6 8.77778C6.73671 8.77778 7.44325 8.48512 7.96419 7.96419C8.48512 7.44325 8.77778 6.73671 8.77778 6C8.77778 5.26329 8.48512 4.55675 7.96419 4.03581C7.44325 3.51488 6.73671 3.22222 6 3.22222C5.26329 3.22222 4.55675 3.51488 4.03581 4.03581C3.51488 4.55675 3.22222 5.26329 3.22222 6C3.22222 6.73671 3.51488 7.44325 4.03581 7.96419C4.55675 8.48512 5.26329 8.77778 6 8.77778ZM3.77778 21C4.51449 21 5.22103 20.7073 5.74196 20.1864C6.2629 19.6655 6.55556 18.9589 6.55556 18.2222C6.55556 17.4855 6.2629 16.779 5.74196 16.258C5.22103 15.7371 4.51449 15.4444 3.77778 15.4444C3.04107 15.4444 2.33453 15.7371 1.81359 16.258C1.29266 16.779 1 17.4855 1 18.2222C1 18.9589 1.29266 19.6655 1.81359 20.1864C2.33453 20.7073 3.04107 21 3.77778 21ZM18.2222 6.55556C18.9589 6.55556 19.6655 6.2629 20.1864 5.74196C20.7073 5.22103 21 4.51449 21 3.77778C21 3.04107 20.7073 2.33453 20.1864 1.81359C19.6655 1.29266 18.9589 1 18.2222 1C17.4855 1 16.779 1.29266 16.258 1.81359C15.7371 2.33453 15.4444 3.04107 15.4444 3.77778C15.4444 4.51449 15.7371 5.22103 16.258 5.74196C16.779 6.2629 17.4855 6.55556 18.2222 6.55556Z"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <p className="text-white text-md sm:text-xl">
                    <span>Technology</span> AI
                  </p>
                </div>
                <div className="description">
                  <img src={image3} className="p-4" loading="lazy"></img>

                  <p>
                    Not just digital but also real-world experiences that
                    enhance learning!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="ellipse">
          <img src={ellipse} alt="" width="280" loading="lazy" />
        </div>
      </div>
    </>
  );
};

export default PedalogyEducationSection;
