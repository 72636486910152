import React from 'react'
import Layout from '../components/Layout'
import BlogDetails from '../components/BlogDetails'
import { useEffect } from "react";

const BlogDetailPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <BlogDetails />
    </Layout>
  )
}

export default BlogDetailPage
