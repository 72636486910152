import React, { useState } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import logo from "../assets/Nav/immverse-logo-tm.svg";
import { Link, NavLink } from "react-router-dom";
import "../style/header.scss";
import { GiHamburgerMenu } from "react-icons/gi";

export default function Header() {
  const [activeItem, setActiveItem] = useState("");

  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
  };

  return (
    <div className="navbarContainer">
      <div className="navbar-inner">
        <NavLink className="navbar-logo" to="/">
          <img src={logo} alt="Logo" className="logo" />
        </NavLink>
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <NavLink
                  className={`nav-link ${activeItem === "home" ? "active" : ""}`}
                  to="/"
                  onClick={() => handleItemClick("home")}
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item dropdown">
                <NavLink
                  className={`nav-link ${activeItem === "about" ? "active" : ""}`}
                  onClick={() => handleItemClick("about")}
                  to="/about"
                >
                  About
                </NavLink>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${activeItem === "iks" ? "" : ""}`}
                  onClick={() => handleItemClick("iks")}
                  href="https://iks.immverse.ai/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  IKS
                </a>
              </li>
              <li className="nav-item">
                <NavLink
                  className={`nav-link ${activeItem === "education" ? "active" : ""}`}
                  onClick={() => handleItemClick("education")}
                  to="/education"
                >
                  Education
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={`nav-link ${activeItem === "fellowship" ? "active" : ""}`}
                  to="/fellowship"
                >
                  Fellowship
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={`nav-link ${activeItem === "blog" ? "active" : ""}`}
                  onClick={() => handleItemClick("blog")}
                  to="/blog"
                >
                  Blog
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={`nav-link ${activeItem === "career" ? "active" : ""}`}
                  onClick={() => handleItemClick("career")}
                  to="/career"
                >
                  Career
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={`nav-link ${activeItem === "gallery" ? "active" : ""}`}
                  onClick={() => handleItemClick("gallery")}
                  to="/teamGallery"
                >
                  Gallery
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={`nav-link ${activeItem === "contactform" ? "active" : ""}`}
                  to="/contactform"
                >
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>

        <div className="responsive-nav">
          <a
            href="#"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasWithBothOptions"
            aria-controls="offcanvasWithBothOptions"
          >
            <GiHamburgerMenu />
          </a>

          <div
            className="offcanvas offcanvas-end"
            data-bs-scroll="true"
            tabIndex="-1"
            id="offcanvasWithBothOptions"
            aria-labelledby="offcanvasWithBothOptionsLabel"
          >
            <div className="offcanvas-header">
              <div
                className="offcanvas-title"
                id="offcanvasWithBothOptionsLabel"
              >
                <NavLink className="navbar-logo" to="/">
                  <img src={logo} alt="Logo" style={{ width: "140px" }} />
                </NavLink>
              </div>
              <a
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                className="close-btn"
              >
                <AiOutlineClose />
              </a>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${activeItem === "home" ? "active" : ""}`}
                    to="/"
                    onClick={() => handleItemClick("home")}
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item dropdown">
                  <NavLink
                    className={`nav-link ${activeItem === "about" ? "active" : ""}`}
                    onClick={() => handleItemClick("about")}
                    to="/about"
                  >
                    About
                  </NavLink>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${activeItem === "iks" ? "" : ""}`}
                    onClick={() => handleItemClick("iks")}
                    href="https://iks.immverse.ai/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    IKS
                  </a>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${activeItem === "education" ? "active" : ""}`}
                    onClick={() => handleItemClick("education")}
                    to="/education"
                  >
                    Education
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${activeItem === "fellowship" ? "active" : ""}`}
                    to="/fellowship"
                  >
                    Fellowship
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${activeItem === "blog" ? "active" : ""}`}
                    onClick={() => handleItemClick("blog")}
                    to="/blog"
                  >
                    Blog
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${activeItem === "career" ? "active" : ""}`}
                    onClick={() => handleItemClick("career")}
                    to="/career"
                  >
                    Career
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${activeItem === "gallery" ? "active" : ""}`}
                    onClick={() => handleItemClick("gallery")}
                    to="/teamGallery"
                  >
                    Gallery
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${activeItem === "contactform" ? "active" : ""}`}
                    to="/contactform"
                  >
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
