import React, { useEffect } from "react";
import Swiper from "swiper/bundle";
import "swiper/swiper-bundle.min.css";
import "./swiper.scss";

import mobile1 from "../assets/Hero/mob1.png";
import mobile2 from "../assets/Hero/mobile2.png";
import mobile3 from "../assets/Hero/mob-2.png";

const MobileSwiper = () => {
  useEffect(() => {
    const mySwiper = new Swiper(".swiper-container", {
      spaceBetween: 1,
      slidesPerView: 3,
      centeredSlides: true,

      loop: true,
      loopFillGroupWithBlank: true,

      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
    });
  }, []);

  return (
    <div className="swiper-container">
      <div className="swiper-wrapper">
        <div className="swiper-slide">
          {" "}
          <img src={mobile2} />
        </div>
        <div className="swiper-slide">
          {" "}
          <img src={mobile3} />
        </div>
        <div className="swiper-slide">
          <img src={mobile1} />
        </div>
      </div>
      <div className="swiper-button-prev"></div>
      <div className="swiper-button-next"></div>
    </div>
  );
};

export default MobileSwiper;
