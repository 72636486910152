import React, { useState } from "react";
import { domainData } from "./Domain";
import axios from "axios";
import Dropdown from "./Dropdown";
import { useEffect } from "react";

const EducationModal = ({ isModalOpen, closeModal }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [formdata, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    schoolname: "",
    designation: "",
    class: "",
    message: "",
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formdata,
      [name]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!formdata.name || !formdata.email || !formdata.message) {
      alert("Please fill in all the required fields.");
      return;
    }

    let data = {
      name: formdata.name,
      email: formdata.email,
      phone: formdata.phone,
      message: formdata.message,
      location: formdata.location,
      designation: formdata.designation,
      schoolname: formdata.schoolname,
      class: formdata.class,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${domainData.url}/save-contact-request`,
      headers: {
        "X-Api-Key": "Immverse-API-sam-0987654321",
      },
      data: data,
    };

    await axios
      .request(config)
      .then((response) => {
        console.log(response);
        if (response.data.msg) {
          console.log(response);
          alert(
            "Thank you for submitting details. Our Team will get back to you shortly."
          );
          closeModal();
          setFormData({
            name: "",
            email: "",
            phone: "",
            schoolname: "",
            designation: "",
            class: "",
            message: "",
          });
        }
      })
      .catch((error) => {
        // throw new error();
        alert("Please fill all the required details");
      });
  };

  const handleDropdownSelect = (selectedValue) => {
    setFormData({
      ...formdata,
      designation: selectedValue,
    });
  };
  return (
    <div>
      {isModalOpen && (
        <div className="absolute top-40 sm:top-40 md:!top-60 lg:!top-40 xl:!top-16 left-0 lg:left-0 w-full h-full flex items-center justify-center">
          <div className="absolute w-full md:w-[75%] lg:w-[60%] xl:w-[50%]  p-6 rounded-lg">
            <div className="container-fluid bg-black h-full w-full ">
              <button
                className="text-[#c3c1c7] absolute right-20 top-20"
                onClick={closeModal}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>

              <div className="container mx-auto flex items-center justify-center flex-col w-4/5 pb-8 text-[#c1c6c7] pt-5 pr-2 pl-2 lg:pl-3 lg:pr-3 md:pr-4 md:pl-4 md:flex-row rounded-xl sm:flex-col sm:pt-4">
                <form
                  className="form flex flex-col w-full gap-4 bg-[rgba(51,49,53,.4)] rounded-xl p-4 md:p-9 mt-12"
                  onSubmit={handleSubmit}
                  id="contact-form"
                >
                  <div className="nameEmail flex flex-col md:flex-row gap-4">
                    <div className="input-box">
                      <label htmlFor="name">Name*</label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        value={formdata.name}
                        onChange={handleInputChange}
                        placeholder="Name"
                        autoComplete="name"
                        className="bg-[hsla(0,0%,34%,.2)] rounded-lg border-white-2 h-10 w-full lg:w-full px-2"
                      />
                    </div>
                    <div className="input-box">
                      <label htmlFor="email">Email*</label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        value={formdata.email}
                        onChange={handleInputChange}
                        placeholder="Email"
                        autoComplete="email"
                        className="bg-[hsla(0,0%,34%,.2)] rounded-lg border-white-2 h-10 w-full lg:w-full px-2"
                      />
                    </div>
                  </div>

                  <div className="nameEmail flex flex-col md:flex-row gap-4">
                    <div className="input-box">
                      <label htmlFor="schoolname">School Name*</label>
                      <input
                        type="text"
                        name="schoolname"
                        id="schoolname"
                        value={formdata.schoolname}
                        onChange={handleInputChange}
                        placeholder="School Name"
                        className="bg-[hsla(0,0%,34%,.2)] rounded-lg border-white-2 h-10 w-full lg:w-full px-2"
                      />
                    </div>
                    <div className="input-box">
                      <label htmlFor="class">Class*</label>
                      <input
                        type="text"
                        name="class"
                        id="class"
                        value={formdata.class}
                        onChange={handleInputChange}
                        placeholder="Ex: 4-10th class"
                        className="bg-[hsla(0,0%,34%,.2)] rounded-lg border-white-2 h-10 w-full lg:w-full px-2"
                      />
                    </div>
                  </div>

                  <div className="nameEmail flex flex-col md:flex-row gap-4">
                    <div className="input-box">
                      <label htmlFor="designation">Designation*</label>
                      <Dropdown onSelect={handleDropdownSelect} />
                    </div>
                    <div className="input-box ">
                      <label htmlFor="location">Location*</label>
                      <input
                        type="text"
                        name="location"
                        id="location"
                        value={formdata.location}
                        onChange={handleInputChange}
                        placeholder="location"
                        className="bg-[hsla(0,0%,34%,.2)] rounded-lg border-white-2 h-10 w-full lg:w-full px-2"
                      />
                    </div>
                  </div>

                  <div className="input-box">
                    <label htmlFor="email">Mobile No*</label>
                    <input
                      type="phone"
                      name="phone"
                      id="phone"
                      value={formdata.phone}
                      onChange={handleInputChange}
                      placeholder="Enter your mobile no."
                      autoComplete="phone"
                      className="bg-[hsla(0,0%,34%,.2)] rounded-lg border-white-2 h-10 w-full lg:w-full px-2"
                    />
                  </div>

                  <div className="textarea-box flex flex-col">
                    <label htmlFor="message">Your Message*</label>
                    <textarea
                      id="message"
                      name="message"
                      className="bg-[hsla(0,0%,34%,.2)] rounded-lg border-white-2 h-20 px-2"
                      placeholder="Enter your message"
                      value={formdata.message}
                      onChange={handleInputChange}
                    ></textarea>
                  </div>
                  <div className="btn-position flex items-center justify-center">
                    <button
                      type="submit"
                      className="w-16 sm:w-1/4 h-10 flex flex-row gap-4 pt-4 rounded-[40px] items-center pl-4 sm:pl-6 md:pl-6 bg-gradient-to-r from-indigo-700 to-pink-700"
                    >
                      <div className="text flex items-center justify-center pb-4 w-full">
                        Send
                      </div>
                      <div className="svg-icon pb-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 20 20"
                          fill="none"
                        ></svg>
                      </div>
                    </button>
                  </div>
                </form>

                {/* <div className=''><Footer/></div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EducationModal;
