import React from 'react'
import { useEffect } from 'react'
import Layout from '../components/Layout'
import AboutComponent from '../components/AboutComponent'

const About = () => {
   useEffect(() => {
    //  window.scrollTo(0, 0);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);

   }, []);
  return (
   <>
   <Layout>
  <AboutComponent/>
   </Layout>
   </>
  )
}

export default About