import React from "react";
import "../style/educationwhatif.scss";
import { ThomasBanner } from "./../assets/Education/ThomasBanner";
import banner from "./../assets/Education/thomasbanner.png";
import ellipse4 from "../assets/About/ellipse4.svg";

const EducationWhatIfComponent = () => {
  return (
    <div className="flex md:flex-row flex-col sm:pt-12 md:gap-12 gap-6 w-[100%] justify-center items-center   md:px-[12%] px-[8%] pt-8 ">
      <div className="gap-2 xl:text-[52px] lg:text-[35px] md:text-[26px] text-[30px] font-bold text-white">
        <div className="ellipse2 absolute top-[219rem] left-0 z-[-1]">
          <img src={ellipse4} alt="" className="w-90" loading="lazy" />
        </div>
        <p className="mb-4">
          <span className="text-pink-600">What If?</span> You Can
        </p>
        <div className="md:gap-4 gap-3 flex flex-col">
          <div className="xl:text-[14px] lg:text-[12px] text-[8px] sm:text-[10px]  font-thin bg-zinc-700 md:pl-2 md:py-2 px-2 py-1 md:mr-12 rounded-full flex items-center gap-2 sm:w-full md:w-full">
            <div className="w-12 lg:h-12 h-8 p-2 rounded-full flex justify-center items-center gradient-bg">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.77778 3.22222L11 1M6.55556 15.4444L15.4444 6.55556M11 21L13.2222 18.7778M16 18.7778C16.7367 18.7778 17.4433 18.4851 17.9642 17.9642C18.4851 17.4433 18.7778 16.7367 18.7778 16C18.7778 15.2633 18.4851 14.5567 17.9642 14.0358C17.4433 13.5149 16.7367 13.2222 16 13.2222C15.2633 13.2222 14.5567 13.5149 14.0358 14.0358C13.5149 14.5567 13.2222 15.2633 13.2222 16C13.2222 16.7367 13.5149 17.4433 14.0358 17.9642C14.5567 18.4851 15.2633 18.7778 16 18.7778ZM6 8.77778C6.73671 8.77778 7.44325 8.48512 7.96419 7.96419C8.48512 7.44325 8.77778 6.73671 8.77778 6C8.77778 5.26329 8.48512 4.55675 7.96419 4.03581C7.44325 3.51488 6.73671 3.22222 6 3.22222C5.26329 3.22222 4.55675 3.51488 4.03581 4.03581C3.51488 4.55675 3.22222 5.26329 3.22222 6C3.22222 6.73671 3.51488 7.44325 4.03581 7.96419C4.55675 8.48512 5.26329 8.77778 6 8.77778ZM3.77778 21C4.51449 21 5.22103 20.7073 5.74196 20.1864C6.2629 19.6655 6.55556 18.9589 6.55556 18.2222C6.55556 17.4855 6.2629 16.779 5.74196 16.258C5.22103 15.7371 4.51449 15.4444 3.77778 15.4444C3.04107 15.4444 2.33453 15.7371 1.81359 16.258C1.29266 16.779 1 17.4855 1 18.2222C1 18.9589 1.29266 19.6655 1.81359 20.1864C2.33453 20.7073 3.04107 21 3.77778 21ZM18.2222 6.55556C18.9589 6.55556 19.6655 6.2629 20.1864 5.74196C20.7073 5.22103 21 4.51449 21 3.77778C21 3.04107 20.7073 2.33453 20.1864 1.81359C19.6655 1.29266 18.9589 1 18.2222 1C17.4855 1 16.779 1.29266 16.258 1.81359C15.7371 2.33453 15.4444 3.04107 15.4444 3.77778C15.4444 4.51449 15.7371 5.22103 16.258 5.74196C16.779 6.2629 17.4855 6.55556 18.2222 6.55556Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            Empower young minds with real-world experiences.
          </div>
          <div className="xl:text-[14px] lg:text-[12px] text-[8px] sm:text-[10px]  font-thin bg-zinc-700 md:pl-2 md:py-2 px-2 py-1 md:mr-12 rounded-full flex items-center gap-2 sm:w-full md:w-full">
            <div className="w-12 lg:h-12 h-8 p-2 rounded-full flex justify-center items-center gradient-bg">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.77778 3.22222L11 1M6.55556 15.4444L15.4444 6.55556M11 21L13.2222 18.7778M16 18.7778C16.7367 18.7778 17.4433 18.4851 17.9642 17.9642C18.4851 17.4433 18.7778 16.7367 18.7778 16C18.7778 15.2633 18.4851 14.5567 17.9642 14.0358C17.4433 13.5149 16.7367 13.2222 16 13.2222C15.2633 13.2222 14.5567 13.5149 14.0358 14.0358C13.5149 14.5567 13.2222 15.2633 13.2222 16C13.2222 16.7367 13.5149 17.4433 14.0358 17.9642C14.5567 18.4851 15.2633 18.7778 16 18.7778ZM6 8.77778C6.73671 8.77778 7.44325 8.48512 7.96419 7.96419C8.48512 7.44325 8.77778 6.73671 8.77778 6C8.77778 5.26329 8.48512 4.55675 7.96419 4.03581C7.44325 3.51488 6.73671 3.22222 6 3.22222C5.26329 3.22222 4.55675 3.51488 4.03581 4.03581C3.51488 4.55675 3.22222 5.26329 3.22222 6C3.22222 6.73671 3.51488 7.44325 4.03581 7.96419C4.55675 8.48512 5.26329 8.77778 6 8.77778ZM3.77778 21C4.51449 21 5.22103 20.7073 5.74196 20.1864C6.2629 19.6655 6.55556 18.9589 6.55556 18.2222C6.55556 17.4855 6.2629 16.779 5.74196 16.258C5.22103 15.7371 4.51449 15.4444 3.77778 15.4444C3.04107 15.4444 2.33453 15.7371 1.81359 16.258C1.29266 16.779 1 17.4855 1 18.2222C1 18.9589 1.29266 19.6655 1.81359 20.1864C2.33453 20.7073 3.04107 21 3.77778 21ZM18.2222 6.55556C18.9589 6.55556 19.6655 6.2629 20.1864 5.74196C20.7073 5.22103 21 4.51449 21 3.77778C21 3.04107 20.7073 2.33453 20.1864 1.81359C19.6655 1.29266 18.9589 1 18.2222 1C17.4855 1 16.779 1.29266 16.258 1.81359C15.7371 2.33453 15.4444 3.04107 15.4444 3.77778C15.4444 4.51449 15.7371 5.22103 16.258 5.74196C16.779 6.2629 17.4855 6.55556 18.2222 6.55556Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
             Blending a Unique Teaching Experience by Humans and Al
          </div>
          <div className="xl:text-[14px] lg:text-[12px] text-[8px] sm:text-[10px]   font-thin bg-zinc-700 md:pl-2 md:py-2 px-2 py-1 md:mr-12 rounded-full flex items-center gap-2 sm:w-full md:w-full">
            <div className="w-12 lg:h-12 h-8 p-2 rounded-full flex justify-center items-center gradient-bg">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.77778 3.22222L11 1M6.55556 15.4444L15.4444 6.55556M11 21L13.2222 18.7778M16 18.7778C16.7367 18.7778 17.4433 18.4851 17.9642 17.9642C18.4851 17.4433 18.7778 16.7367 18.7778 16C18.7778 15.2633 18.4851 14.5567 17.9642 14.0358C17.4433 13.5149 16.7367 13.2222 16 13.2222C15.2633 13.2222 14.5567 13.5149 14.0358 14.0358C13.5149 14.5567 13.2222 15.2633 13.2222 16C13.2222 16.7367 13.5149 17.4433 14.0358 17.9642C14.5567 18.4851 15.2633 18.7778 16 18.7778ZM6 8.77778C6.73671 8.77778 7.44325 8.48512 7.96419 7.96419C8.48512 7.44325 8.77778 6.73671 8.77778 6C8.77778 5.26329 8.48512 4.55675 7.96419 4.03581C7.44325 3.51488 6.73671 3.22222 6 3.22222C5.26329 3.22222 4.55675 3.51488 4.03581 4.03581C3.51488 4.55675 3.22222 5.26329 3.22222 6C3.22222 6.73671 3.51488 7.44325 4.03581 7.96419C4.55675 8.48512 5.26329 8.77778 6 8.77778ZM3.77778 21C4.51449 21 5.22103 20.7073 5.74196 20.1864C6.2629 19.6655 6.55556 18.9589 6.55556 18.2222C6.55556 17.4855 6.2629 16.779 5.74196 16.258C5.22103 15.7371 4.51449 15.4444 3.77778 15.4444C3.04107 15.4444 2.33453 15.7371 1.81359 16.258C1.29266 16.779 1 17.4855 1 18.2222C1 18.9589 1.29266 19.6655 1.81359 20.1864C2.33453 20.7073 3.04107 21 3.77778 21ZM18.2222 6.55556C18.9589 6.55556 19.6655 6.2629 20.1864 5.74196C20.7073 5.22103 21 4.51449 21 3.77778C21 3.04107 20.7073 2.33453 20.1864 1.81359C19.6655 1.29266 18.9589 1 18.2222 1C17.4855 1 16.779 1.29266 16.258 1.81359C15.7371 2.33453 15.4444 3.04107 15.4444 3.77778C15.4444 4.51449 15.7371 5.22103 16.258 5.74196C16.779 6.2629 17.4855 6.55556 18.2222 6.55556Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            Interact and learn from historical figures across timelines.
          </div>
        </div>
      </div>
      <div className="md:w-[50%]">
        <img
          className="lg:w-[80rem] h-64 md:h-72 xl:h-full md:min-w-[20rem]"
          src={banner}
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default EducationWhatIfComponent;
