import React, { useState, useEffect } from "react";
import { domainData } from "../Domain";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import FellowshipDropdown from "./FellowshipAlanTuringformdropdown";

const FellowshipForm = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
    setLoader(false);
  }, []);
  const [formdata, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    resume: "",
    resource_link: "",
    fellowship: "Alan Turing AI fellowship",
    message_sec1: "",
    message_sec2: "",
    message_sec3: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formdata,
      [name]: value,
    });
  };

  //   let file = e.target.elements.file.files[0];

  //   let data = {
  //     first_name: formdata.first_name,
  //     lastname: formdata.lastname,
  //     email: formdata.email,
  //     phone: formdata.phone,
  //     text_section_one: formdata.message_sec1,
  //     text_section_two: formdata.message_sec2,
  //     text_section_three: formdata.message_sec3,
  //     link: formdata.link,
  //     fellowship: formdata.fellowship,
  //     file: file,
  //   };

  //   let formData = new FormData();

  //   for (let key in data) {
  //     formData.append(key, data[key]);
  //   }

  //   let config = {
  //     method: "post",
  //     maxBodyLength: Infinity,
  //     url: `${domainData.url}/save-fellowship-request`,
  //     headers: {
  //       "X-Api-Key": "Immverse-API-sam-0987654321",
  //       "Content-Type": "multipart/form-data",
  //     },
  //     data: formData,
  //   };

  //   try {
  //     const response = await axios.request(config);
  //     console.log("Response", response.data);

  //     if (response.data) {
  //       console.log(response);
  //       alert(
  //         "Thank you for submitting details. Our Team will get back to you shortly."
  //       );
  //       navigate(-1);
  //     }
  //   } catch (error) {
  //     alert("Please fill all the required details");
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (e.nativeEvent.submitter.name === "apply") {
      let file = e.target.resume.files[0];

      let data = {
        first_name: formdata.first_name,
        last_name: formdata.last_name,
        email: formdata.email,
        phone_number: formdata.phone_number,
        text_section_one:
          "type of fellowship :" +
          formdata.fellowship +
          " text sec : " +
          formdata.message_sec1,
        text_section_two: formdata.message_sec2,
        text_section_three: formdata.message_sec3,
        resource_link: formdata.resource_link,

        resume: file,
      };

      let formData = new FormData();

      for (let key in data) {
        formData.append(key, data[key]);
      }

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${domainData.url}/save-fellowship-request`,
        headers: {
          "X-Api-Key": "Immverse-API-sam-0987654321",
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      try {
        const response = await axios.request(config);
        console.log("Response", response.data);

        if (response.data) {
          console.log(response);
          alert(
            "Thank you for submitting details. Our Team will get back to you shortly."
          );
          // formdata = {
          //   first_name: "",
          //   last_name: "",
          //   email: "",
          //   phone_number: "",

          //   message_sec1: "",
          //   message_sec2: "",
          //   message_sec3: "",
          //   resource_link: "",
          // };
          navigate(-1);
        }
      } catch (error) {
        console.log(error);
        alert("Please fill all the required details");
      }
    }
  };

  const handleDropdownSelect = (selectedValue) => {
    console.log("formdata", formdata);
    setFormData({
      ...formdata,
      fellowship: selectedValue,
    });
  };
  return (
    <div>
      <div className="absolute top-4 sm:top-96 left-0 w-full  flex items-center justify-center  ">
        <div className="absolute w-full sm:w-4/5 p-6 rounded-lg">
          <div className="container-fluid  h-full w-full ">
            <button
              className="back-button pt-[78rem] sm:pt-[40rem] md:pt-[23rem] rounded-lg"
              onClick={() => navigate(-1)}
            >
              <svg
                width="40"
                height="40"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <linearGradient
                    id="gradient"
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="100%"
                  >
                    <stop offset="0%" stop-color="#d71d89" />
                    <stop offset="100%" stop-color="#3f42db" />
                  </linearGradient>
                </defs>
                <rect
                  x="0.5"
                  y="0.5"
                  width="59"
                  height="59"
                  rx="29.5"
                  fill="url(#gradient)"
                  stroke="pink"
                />
                <path
                  d="M24.47 30.07C24.41 29.83 24.38 29.56 24.38 29.26C24.38 28.94 24.41 28.72 24.47 28.6C24.67 28.4 25.62 27.48 27.32 25.84C29.04 24.2 30.09 23.2 30.47 22.84C31.17 22.22 32.14 21.49 33.38 20.65L34.58 21.97C30.78 25.65 28.6 27.74 28.04 28.24C27.5 28.74 27.11 29.08 26.87 29.26C27.51 29.78 27.85 30.06 27.89 30.1L34.58 36.52L33.38 37.84C32.3 37.12 31.44 36.49 30.8 35.95C30.18 35.39 29.01 34.31 27.29 32.71C25.57 31.11 24.63 30.23 24.47 30.07Z"
                  fill="pink"
                />
              </svg>
            </button>
            <div className="container mx-auto flex items-center justify-center flex-col w-full sm:w-4/5 pb-8 text-[#c1c6c7] pt-0 pr-2 pl-2 lg:pl-3 lg:pr-3 md:pr-4 md:pl-4 md:flex-row rounded-xl sm:flex-col sm:pt-4">
              <form
                className="form flex flex-col w-full gap-4 rounded-xl p-4 md:p-9"
                onSubmit={handleSubmit}
                id="contact-form"
              >
                <div className="nameEmail flex flex-col md:flex-row gap-4 ">
                  <div className="input-box w-full lg:w-1/2">
                    <label htmlFor="name">First Name*</label>
                    <input
                      type="text"
                      name="first_name"
                      id="firstname"
                      value={formdata.first_name}
                      onChange={handleInputChange}
                      placeholder="First Name"
                      autoComplete="first name"
                      className="bg-[hsla(0,0%,34%,.2)] rounded-lg border-white-2 h-10 w-full lg:w-full px-2"
                    />
                  </div>

                  <div className="input-box w-full lg:w-1/2">
                    <label htmlFor="name">Last Name*</label>
                    <input
                      type="text"
                      name="last_name"
                      id="last_name"
                      value={formdata.last_name}
                      onChange={handleInputChange}
                      placeholder="Last Name"
                      autoComplete="last name"
                      className="bg-[hsla(0,0%,34%,.2)] rounded-lg border-white-2 h-10 w-full lg:w-full px-2"
                    />
                  </div>
                </div>

                <div className="nameEmail ">
                  <div className="input-box">
                    <label htmlFor="email">Email*</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={formdata.email}
                      onChange={handleInputChange}
                      placeholder="Email"
                      autoComplete="email"
                      className="bg-[hsla(0,0%,34%,.2)] rounded-lg border-white-2 h-10 w-full lg:w-full px-2"
                    />
                  </div>
                </div>

                <div className="nameEmail ">
                  <div className="input-box">
                    <label htmlFor="fellowship" name="fellowship">
                      Fellowship*
                    </label>
                    <FellowshipDropdown onSelect={handleDropdownSelect} />
                  </div>
                </div>

                <div className="input-box">
                  <label htmlFor="mobile">Mobile No*</label>
                  <input
                    type="phone_number"
                    name="phone_number"
                    id="phone_number"
                    value={formdata.phone_number}
                    onChange={handleInputChange}
                    placeholder="Enter your mobile no."
                    autoComplete="phone"
                    className="bg-[hsla(0,0%,34%,.2)] rounded-lg border-white-2 h-10 w-full lg:w-full px-2"
                  />
                </div>

                <div className="textarea-box flex flex-col">
                  <label htmlFor="message_sec1">
                    What Problem you want to solve in the AI/ML space?*
                  </label>
                  <textarea
                    id="message_sec1"
                    name="message_sec1"
                    className="bg-[hsla(0,0%,34%,.2)] rounded-lg border-white-2 h-20 px-2"
                    placeholder="Enter your message"
                    value={formdata.message_sec1}
                    onChange={handleInputChange}
                  ></textarea>
                </div>

                <div className="textarea-box flex flex-col">
                  <label htmlFor="message_sec2">
                    What cool stuff have you worked on in the AI/ML space?*
                  </label>
                  <textarea
                    id="message_sec2"
                    name="message_sec2"
                    className="bg-[hsla(0,0%,34%,.2)] rounded-lg border-white-2 h-20 px-2"
                    placeholder="Enter your message"
                    value={formdata.message_sec2}
                    onChange={handleInputChange}
                  ></textarea>
                </div>

                <div className="textarea-box flex flex-col">
                  <label htmlFor="message_sec3">
                    Tell Us something tangible you want from this fellowship*
                  </label>
                  <textarea
                    id="message_sec3"
                    name="message_sec3"
                    className="bg-[hsla(0,0%,34%,.2)] rounded-lg border-white-2 h-20 px-2"
                    placeholder="Enter your message"
                    value={formdata.message_sec3}
                    onChange={handleInputChange}
                  ></textarea>
                </div>

                <div className="input-box">
                  <label htmlFor="link">Link*</label>
                  <input
                    type="text"
                    name="resource_link"
                    id="resource_link"
                    value={formdata.resource_link}
                    onChange={handleInputChange}
                    placeholder="Share your portfolio, work samples or GitHub link"
                    autoComplete="text"
                    className="bg-[hsla(0,0%,34%,.2)] rounded-lg border-white-2 h-10 w-full lg:w-full px-2"
                  />
                </div>
                <div className="input-box">
                  <label htmlFor="file">Additional File</label>
                  <input
                    type="file"
                    name="resume"
                    id="resume"
                    accept="video/*, image/*, .pdf"
                    className="bg-[hsla(0,0%,34%,.2)] rounded-lg border-white-2 h-10 w-full lg:w-full px-2 pt-[0.3rem]"
                  />
                </div>
                <div className="btn-position flex items-center justify-center">
                  <button
                    type="submit"
                    name="apply"
                    className="w-24 sm:w-1/4 h-10 flex flex-row gap-4 pt-4 rounded-[40px] pl-8 md:pl-10 bg-gradient-to-r from-indigo-700 to-pink-700 items-center justify-center"
                  >
                    <div className="text flex items-center justify-center pb-4 w-full">
                      Send
                    </div>
                    <div className="svg-icon pb-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 20 20"
                        fill="none"
                      ></svg>
                    </div>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FellowshipForm;
