import React, { useState, useEffect } from "react";
import macbook from "../assets/Education/education-image.png";
import YouTube from "react-youtube";
import HTMLFlipBook from "react-pageflip";
// import React from 'react';
import MyBook from "./PageFlipWrapperComponent";
import MyAlbum from "./PageFlipWrapperComponent";
const EducationVideo = () => {
  const [loader, setLoader] = useState(true);
  const options = {
    width: 400,
    height: 300,
    size: "fixed",
    minWidth: 200,
    minHeight: 150,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoader(false);
  }, []);

  return (
    <>
      <div className="video w-full flex justify-center items-center mt-20">
        <div className="cover-img relative w-4/5">
          <img
            src={macbook}
            alt=""
            loading="lazy"
            className="w-full rounded-xl"
          />
        </div>
      </div>

      <div className="App">
        <MyAlbum />
      </div>
    </>
  );
};

export default EducationVideo;
