import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRocket,
  faGlobe,
  faUtensils,
  faArrowUp,
  faWineGlass,
  faTicket,
  faFlask,
  faHandshake,
  faSeedling,
} from "@fortawesome/free-solid-svg-icons";
import "../style/cardslider.scss";
import ellipse3 from "../assets/About/ellipse3.svg";

const Card = () => {
  const horizontalData = [
    {
      icon: faRocket,
      heading: "Mission",
      text: "Join and be part of a mission and be proud to be an ImmverseAI-(ian) ",
    },
    {
      icon: faGlobe,
      heading: "AI-FIRST",
      text: "You will be building an AI-FIRST world, first to the world! ",
    },
    {
      icon: faUtensils,
      heading: "Culture",
      text: "Culture eats strategy for breakfast, lunch & dinner",
    },
  ];

  const verticalData = [
    {
      icon: faArrowUp,
      heading: "Up",
      text: "Elevate your career game by working on the bleeding-edge AI tech stack ",
    },
    {
      icon: faWineGlass,
      heading: "Celebrate ",
      text: "We celebrate failures! ",
    },
    {
      icon: faTicket,
      heading: "ImmverseAI",
      text: "Your ticket to the front row of the AI revolution ",
    },
  ];

  const rowData = [
    {
      icon: faFlask,
      heading: "Innovate",
      text: "Drive innovation with ImmverseAI, where ideas come to life!",
    },
    {
      icon: faHandshake,
      heading: "Collaborate",
      text: "Join a community that values teamwork and collaboration.",
    },
    {
      icon: faSeedling,
      heading: "Growth",
      text: "Experience personal and professional growth at ImmverseAI.",
    },
  ];

  return (
    <div className="card-main-container">
      <div className="card-container">
        <div className="horizontal-cards">
          {horizontalData.map((data, index) => (
            <div key={index} className="card horizontal-card">
              <div
                className="icon-content"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={data.icon}
                  size="2x"
                  className="fa-icon"
                />
                <h3
                  style={{
                    color: "#d71d89",
                    fontWeight: "700",
                    fontSize: "25px",
                  }}
                >
                  {data.heading}
                </h3>
              </div>
              <p>{data.text}</p>
            </div>
          ))}
        </div>

        <div className="vertical-cards">
          {verticalData.map((data, index) => (
            <div key={index} className="card vertical-card">
              <div
                className="icon-content"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={data.icon}
                  size="2x"
                  className="fa-icon"
                />
                <h3
                  style={{
                    color: "#d71d89",
                    fontWeight: "700",
                    fontSize: "25px",
                  }}
                >
                  {data.heading}
                </h3>
              </div>

              <p>{data.text}</p>
            </div>
          ))}
        </div>

        <div className="horizontal-cards-3">
          {rowData.map((data, index) => (
            <div key={index} className="card horizontal-card">
              {/* <div className="icon-content">
                <FontAwesomeIcon
                  icon={data.icon}
                  size="2x"
                  style={{
                    color: "#c3c1c7",
                    background:
                      "linear-gradient(246deg, #d71d89 -5.43%, #3f42db 102.46%)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "3rem",
                    marginLeft: "6rem",
                    borderRadius: "50%",
                    marginTop: "-2rem",
                    height: "3rem",
                    padding: "10px",
                  }}
                />
                <h3
                  style={{
                    color: "#d71d89",
                    fontWeight: "700",
                    fontSize: "25px",
                  }}
                >
                  {data.heading}
                </h3>
              </div> */}

              <div
                className="icon-content"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={data.icon}
                  size="2x"
                  className="fa-icon"
                />
                <h3
                  style={{
                    color: "#d71d89",
                    fontWeight: "700",
                    fontSize: "25px",
                  }}
                >
                  {data.heading}
                </h3>
              </div>

              <div>
                <p>{data.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Card;
