import React, { useEffect, useState } from "react";
import ellipse3 from "../assets/About/ellipse3.svg";
import ellipse4 from "../assets/About/ellipse4.svg";
import "../style/blogdetail.scss";
import immverse from "../assets/Blog/immverseblogdetail.png";
import immverseellipse from "../assets/Blog/immverse-ellipse.svg";
import latestpost from "../assets/Blog/latestpost.svg";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Contactform from "./Contactform";
import { domainData } from "./Domain";
import { Helmet } from "react-helmet-async";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ContentLoader from "react-content-loader";
import BlogLoadingComponent from "../components/BlogLoadingComponent";
import BlogdetailComponent from "./BlogdetailComponent";
import LoadingComponent from "./loader";

const BlogDetails = () => {
  const id = useParams();
  const [data, setData] = useState();
  const [loader, setLoader] = useState(true);

  const getData = async () => {
    if (id?.id) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${domainData.url}/get-blog-details?id=${id.id}`,
        headers: {
          "x-api-key": "Immverse-API-sam-0987654321",
        },
      };

      await axios
        .request(config)
        .then((response) => {
          setLoader(false);
          setData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, [id]);

  const handleLinkClick = () => {
    setLoader(true);
  };

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Discover the future of avatars and how Immverse AI is revolutionizing digital identity. Learn about our breakthrough technologies and innovations.

          "
        />
        <meta
          name="keywords"
          content="Immverse, Immverse AI, ImmverseAI,Artificial Intelligence, AI, Personalized AI, Immortalizing
        Humans, Human Preservation, Digital Avatar, Virtual Presence"
        />
        <meta name="author" content="Immverse AI" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.immverseai.ai/" />
        <meta
          property="og:title"
          content="Immverse AI | Immortalizing Humanity through AI"
        />
        <meta property="og:image" content="/immverseLogo.svg" />
        <meta property="twitter:card" content="/immverseLogo.svg" />
        <meta property="twitter:url" content="https://www.immverseai.ai/" />
        <meta
          property="twitter:title"
          content="ImmverseAI - Welcome To ImmverseAI"
        />
        <meta
          property="twitter:description"
          content="Discover the future of avatars and how Immverse AI is revolutionizing digital identity. Learn about our breakthrough technologies and innovations.

          "
        />
        <title>ImmverseAI - {data?.blog?.blog_title ?? ""}</title>
      </Helmet>
      <div className="blogdetails-container">
        <div className="blogdetails-content">
          <div className="ellipse3">
            <img src={ellipse3} alt="" width="600" loading="lazy" />
          </div>
          <div className="ellipse4">
            <img src={ellipse4} alt="" width="600" loading="lazy" />
          </div>
          <div className="blogdetailsmain">
            <div className="blogDetails">
              <div>
                {loader ? (
                  <BlogdetailComponent />
                ) : (
                  <img
                    src={data?.blog?.main_image?.original_url}
                    alt=""
                    style={{ borderRadius: "20px 20px 0px 0px" }}
                    loading="lazy"
                  />
                )}
              </div>
              <div className="blogcontent">
                <p className="generativeAI">
                  {data?.blog?.blog_category?.category}
                </p>
                <h1>{data?.blog?.blog_title}</h1>
                <div className="teamImmverse">
                  <img src={immverseellipse} alt="" loading="lazy" />
                  <p>Team ImmverseAI</p>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.blog?.blog_content,
                  }}
                  className="description"
                ></div>
              </div>
            </div>

            <div className="Latest">
              <div className="latestPosts">
                <h1>Latest Post</h1>
                {loader ? (
                  <LoadingComponent />
                ) : (
                  data?.blogsLatest?.map((post) => (
                    <Link
                      onClick={handleLinkClick}
                      key={post?.id}
                      to={`/blogdetails/${post?.id}`}
                    >
                      <div className="latestpost">
                        <div>
                          <img
                            src={post?.main_image?.preview_url}
                            alt=""
                            loading="lazy"
                          />
                        </div>
                        <div className="latestpostname">
                          <div className="head">
                            <p className="generativeAI">
                              {post?.blog_category?.category}
                            </p>
                            <h1>{post?.blog_title.slice(0, 16)}...</h1>
                          </div>
                          <div className="date">
                            <p>{post?.created_at}</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))
                )}
              </div>
              <div className="latestCategory">
                <h1>Latest Categories</h1>
                <ul>
                  {data?.blogCategories?.map((category) => (
                    <li>{category.category}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <Contactform />
        </div>
        <hr />
      </div>
    </>
  );
};

export default BlogDetails;
